import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetOrderById } from './order';

export const useOrder = (orderId: string, fetchOnce = false) => {
    const { getOrderById } = useGetOrderById();

    return useSuspenseQuery({
        queryKey: ['order', orderId],
        queryFn: async () => getOrderById(orderId),
        ...(fetchOnce && { staleTime: Infinity, cacheTime: Infinity }),
        refetchOnWindowFocus: false,
    });
};

// @Deprecated This hook should be replaced with `useMarketContext`.
export const useMarketOfOrder = (orderId: string) => {
    const { getOrderById } = useGetOrderById();

    const query = useSuspenseQuery({
        queryKey: ['order', orderId],
        queryFn: async () => getOrderById(orderId),
    });

    return query.data?.marketCode;
};
