import { FallbackProps } from 'react-error-boundary';
import { Button } from '@schuettflix/planum-react';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type SectionErrorFallbackProps = FallbackProps;

export function SectionErrorFallback() {
    const { title, message } = useErrorMessaging();
    return <ErrorBox title={title} message={message} />;
}

export function SectionErrorFallbackWithReset({ resetErrorBoundary }: SectionErrorFallbackProps) {
    const { title, message } = useErrorMessaging();
    const { t } = useTranslation();

    return (
        <ErrorBox title={title} message={message}>
            <div>
                <Button onPress={resetErrorBoundary} className="whitespace-pre">
                    {t('errorMessages.generic.unexpected_error.reset_action')}
                </Button>
            </div>
        </ErrorBox>
    );
}

type ErrorBoxProps = PropsWithChildren & {
    title: string;
    message: string;
};

function ErrorBox({ title, message, children }: ErrorBoxProps) {
    return (
        <div className="border-critical w-full max-w-[720px] rounded border bg-red-200 p-4">
            <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-2">
                    <h2 className="font-copy-md text-red-800" aria-disabled>
                        {title}
                    </h2>
                    <div className="font-copy-sm">{message}</div>
                </div>
                {children}
            </div>
        </div>
    );
}

function useErrorMessaging() {
    const { t } = useTranslation();

    return {
        title: t('errorMessages.generic.unexpected_error.title'),
        message: t('errorMessages.generic.unexpected_error.message'),
    };
}
