import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { LoggedInUser, LoggedInUserSchema } from '@/clients/organization/types/loggedInUser.ts';
import { setIdentityForUserflow } from '@/tools/userflow.ts';
import { getUserLocaleFromLocalStorage } from '@/shared/utils/localStorage.ts';
import * as Sentry from '@sentry/react';
import { FullscreenLoadingSpinner } from '@/shared/components/FullscreenLoadingSpinner.tsx';
import { Outlet } from 'react-router';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export function PostAuthenticationInitialization() {
    const [initializationStatus, setInitializationStatus] = useState<'ready' | 'running' | 'complete'>('ready');
    const { monolithClient } = useHttpClientContext();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (initializationStatus === 'ready') {
            setInitializationStatus('running');

            monolithClient
                .get<LoggedInUser>('/v2/me')
                .then(res => LoggedInUserSchema.parse(res.data))
                .then(async ({ user, organization }) => {
                    const [userflowResult, i18nResult] = await Promise.allSettled([
                        setIdentityForUserflow(user, organization, i18n.language),
                        i18n.changeLanguage(getUserLocaleFromLocalStorage() || user.locale),
                    ]);

                    if (userflowResult.status === 'rejected') {
                        Sentry.captureException(
                            new Error('Failed to set user identify for Userflow', { cause: userflowResult.reason })
                        );
                    }

                    if (i18nResult.status === 'rejected') {
                        Sentry.captureException(
                            new Error('Failed to change internationalization language', { cause: i18nResult.reason })
                        );
                    }

                    setInitializationStatus('complete');
                })
                .catch(e => {
                    Sentry.captureException(new Error('Failed to fetch user data from monolith', { cause: e }));
                });
        }
    }, [initializationStatus, monolithClient, i18n]);

    if (initializationStatus !== 'complete') {
        return <FullscreenLoadingSpinner />;
    }

    return <Outlet />;
}
