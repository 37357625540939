import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const PostReferenceDocumentDuplicateRequestSchema = z.object({
    referenceDocument: z.string(),
    serviceDate: z.coerce.date(),
    partnerOrganizationId: z.number(),
    quantity: z.number().gte(0),
    unit: SUPPORTED_UNITS_SCHEMA,
});

export const PostReferenceDocumentDuplicateCheckResponseSchema = z.object({
    usedBy: z.array(
        z.object({
            orderNumber: z.string(),
            transportNumber: z.string().nullish(),
        })
    ),
});
