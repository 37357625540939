import { Outlet, useParams } from 'react-router';
import { MarketContextProvider } from '@/shared/context/MarketContext.tsx';
import { usePosition } from '@/clients/position/usePosition.ts';
import { MarketCodeSchema } from '@/shared/types/MarketCode.ts';
import { HttpClientContextProvider } from '@/shared/context/HttpClientContext.tsx';

type PositionRouteParams = { positionId: string };

function InnerPositionRoute({ positionId }: PositionRouteParams) {
    const { data } = usePosition(positionId);

    if (!data) {
        return;
    }

    const safeMarketCode = MarketCodeSchema.safeParse(data.marketCode);
    if (!safeMarketCode.success) {
        throw new Error(`Invalid market code: ${data.marketCode}`);
    }

    return (
        <MarketContextProvider marketCode={safeMarketCode.data}>
            <HttpClientContextProvider marketCode={safeMarketCode.data}>
                <Outlet />
            </HttpClientContextProvider>
        </MarketContextProvider>
    );
}

export function RouteWithPositionId() {
    const { positionId } = useParams<PositionRouteParams>();

    if (!positionId) {
        return;
    }

    return <InnerPositionRoute positionId={positionId} />;
}
