import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetOrderSummaryById } from './order';

export function getOrderSummaryQueryKey(orderId: string) {
    return ['order-summary', orderId];
}

export const useOrderSummary = (orderId: string) => {
    const { getOrderSummaryById } = useGetOrderSummaryById();

    return useSuspenseQuery({
        queryKey: getOrderSummaryQueryKey(orderId),
        queryFn: () => getOrderSummaryById(orderId),
        refetchOnWindowFocus: false,
    });
};
