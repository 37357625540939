import {
    CreatePositionOrderResponseSchema,
    CreatePositionOrderUrl,
    GetPositionProductGroupsResponseSchema,
    GetPositionResponseSchema,
    GetPositionSummaryResponseSchema,
    PatchPositionGroupRequestSchema,
    PatchPositionRequestSchema,
    PatchPositionResponseSchema,
    PostPositionGroupRequestSchema,
    PostPositionProductRequestSchema,
    PostPositionProductResponseSchema,
    PostPositionStartEditResponse,
    PostPositionStartEditResponseSchema,
    PostPositionSubmitResponse,
    PostPositionSubmitResponseSchema,
} from '@schuettflix/interfaces';
import { z } from 'zod';
import { useDecorateOrganizationNamesByOrganizationIds } from '@/clients/organization/Organization.ts';
import * as Sentry from '@sentry/react';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetPositionById = () => {
    const { positionClient } = useHttpClientContext();
    return {
        getPositionById: (positionId: string) =>
            positionClient
                .get<z.input<typeof GetPositionResponseSchema>>(`/v1/position/${positionId}`)
                .then(res => GetPositionResponseSchema.parse(res.data)),
    };
};

export const usePatchPosition = () => {
    const { positionClient } = useHttpClientContext();
    return {
        patchPosition: ({
            body,
            positionId,
        }: {
            body: z.input<typeof PatchPositionRequestSchema>;
            positionId: string;
        }) =>
            positionClient
                .patch<z.input<typeof PatchPositionResponseSchema>>(`/v1/position/${positionId}`, body)
                .then(res => PatchPositionResponseSchema.parse(res.data)),
    };
};

export const useCreatePositionProductGroup = () => {
    const { positionClient } = useHttpClientContext();
    return {
        createPositionProductGroup: (
            positionId: string,
            productGroup: z.infer<typeof PostPositionGroupRequestSchema>
        ) =>
            positionClient
                .post<
                    z.input<typeof PostPositionGroupRequestSchema>
                >(`/v1/position/${positionId}/product-group`, productGroup)
                .then(res => PostPositionGroupRequestSchema.parse(res.data)),
    };
};

export const useGetPositionProductGroups = () => {
    const { positionClient } = useHttpClientContext();
    return {
        getPositionProductGroups: (positionId: string) =>
            positionClient
                .get<z.input<typeof GetPositionProductGroupsResponseSchema>>(`/v1/position/${positionId}/product-group`)
                .then(res => GetPositionProductGroupsResponseSchema.parse(res.data)),
    };
};

export const useGetPositionSummaryById = () => {
    const { positionClient } = useHttpClientContext();
    const { decorateOrganizationNamesByOrganizationIds } = useDecorateOrganizationNamesByOrganizationIds();
    return {
        getPositionSummaryById: async (positionId: string) => {
            try {
                const positionSummary = await positionClient
                    .get<z.input<typeof GetPositionSummaryResponseSchema>>(`/v1/position/${positionId}/summary`)
                    .then(res => GetPositionSummaryResponseSchema.parse(res.data));
                return decorateOrganizationNamesByOrganizationIds(positionSummary.organizations);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    };
};

export const useUpdatePositionProductGroup = () => {
    const { positionClient } = useHttpClientContext();
    return {
        updatePositionProductGroup: (
            positionId: string,
            productGroupId: string,
            productGroup: z.infer<typeof PatchPositionGroupRequestSchema>
        ) => positionClient.patch<void>(`/v1/position/${positionId}/product-group/${productGroupId}`, productGroup),
    };
};

export const useDeletePositionProductGroup = () => {
    const { positionClient } = useHttpClientContext();
    return {
        deletePositionProductGroup: async (positionId: string, productGroupId: string) => {
            await positionClient.delete(`/v1/position/${positionId}/product-group/${productGroupId}`);
            return;
        },
    };
};

export const useDeletePositionProduct = () => {
    const { positionClient } = useHttpClientContext();
    return {
        deletePositionProduct: async (positionId: string, productGroupId: string, productId: string) => {
            await positionClient.delete(
                `/v1/position/${positionId}/product-group/${productGroupId}/product/${productId}`
            );
            return;
        },
    };
};

export const useCreatePositionProduct = () => {
    const { positionClient } = useHttpClientContext();
    return {
        createPositionProduct: (
            positionId: string,
            productGroupId: string,
            product: z.infer<typeof PostPositionProductRequestSchema>
        ) =>
            positionClient
                .post<
                    z.input<typeof PostPositionProductRequestSchema>
                >(`/v1/position/${positionId}/product-group/${productGroupId}/product`, product)
                .then(res => PostPositionProductResponseSchema.parse(res.data)),
    };
};

export const useCreatePositionSubmit = () => {
    const { positionClient } = useHttpClientContext();
    return {
        createPositionSubmit: (positionId: string) =>
            positionClient
                .post<PostPositionSubmitResponse>(`/v1/position/${positionId}/submit`)
                .then(res => PostPositionSubmitResponseSchema.parse(res.data)),
    };
};

export const useStartEditPosition = () => {
    const { positionClient } = useHttpClientContext();
    return {
        startEditPosition: (positionId: string) =>
            positionClient
                .post<PostPositionStartEditResponse>(`/v1/position/${positionId}/edit`)
                .then(res => PostPositionStartEditResponseSchema.parse(res.data)),
    };
};

export const useCommitEditPosition = () => {
    const { positionClient } = useHttpClientContext();
    return {
        commitEditPosition: (positionId: string, temporaryId: string) =>
            positionClient.post<void>(`/v1/position/${positionId}/commit-edit/${temporaryId}`),
    };
};

export const useCreatePositionOrder = () => {
    const { positionClient } = useHttpClientContext();
    return {
        createPositionOrder: (positionId: string) =>
            positionClient
                .post<CreatePositionOrderUrl>(`/v1/position/${positionId}/order`)
                .then(response => CreatePositionOrderResponseSchema.parse(response.data)),
    };
};
