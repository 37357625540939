import React, { ReactNode, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { FullscreenLoadingSpinner } from '@/shared/components/FullscreenLoadingSpinner.tsx';
import { useIdTokenContext } from '@/shared/context/IdTokenContext.tsx';
import { PostAuthenticationInitialization } from '@/routes/PostAuthenticationInitialization.tsx';
import { HttpClientContextProvider } from '@/shared/context/HttpClientContext.tsx';
import { FeatureFlagProvider } from '@/tools/featureFlags/FeatureFlagProvider';
import { MarketContextProvider, useMarketContext } from '@/shared/context/MarketContext.tsx';

function HttpClientContextProviderWithMarketFromContext({ children }: { children: ReactNode }) {
    const { marketCode } = useMarketContext();

    return <HttpClientContextProvider marketCode={marketCode}>{children}</HttpClientContextProvider>;
}

function EnhancedOutlet() {
    const { getDefaultMarketCode, getIdTokenPayload } = useIdTokenContext();
    const payload = getIdTokenPayload();

    return (
        <FeatureFlagProvider email={payload.email} name={payload.name}>
            <MarketContextProvider marketCode={getDefaultMarketCode()}>
                <HttpClientContextProviderWithMarketFromContext>
                    <PostAuthenticationInitialization />
                </HttpClientContextProviderWithMarketFromContext>
            </MarketContextProvider>
        </FeatureFlagProvider>
    );
}

export function AuthGuard() {
    // Initial authState is ALWAYS null! See: https://github.com/okta/okta-react?tab=readme-ov-file#available-hooks
    // This is why this AuthGuard initializes all IdToken or user related assets,
    // in addition to initiating the authentication process.
    // Without this AuthGuard used in AuthenticatedRoutes, the user is not logged in!
    // See also: https://github.com/okta/okta-react?tab=readme-ov-file#react-router-components-optional
    const { oktaAuth, authState } = useOktaAuth();
    const { initialize, isInitialized } = useIdTokenContext();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect().catch(error => {
                console.error('Error while redirecting to login', error);
            });
        }
    }, [oktaAuth, authState, authState?.isAuthenticated]);

    useEffect(() => {
        if (authState && authState.idToken) {
            initialize(authState.idToken.idToken);
        }
    }, [authState, authState?.idToken, initialize]);

    if (!isInitialized()) {
        return <FullscreenLoadingSpinner />;
    }

    return <EnhancedOutlet />;
}
