import { SUPPORTED_UNITS } from '@schuettflix/interfaces';
import { useFulfillmentContext } from '../context/FulfillmentContext';
import { useMemo } from 'react';
import { useDeepCompareEffect } from 'react-use';

export type FulfillmentDiffData = {
    productId: string;
    productName: string;
    unit: SUPPORTED_UNITS;
    originalAmount: number;
    newAmount: number;
};

const allowedDiffThreshold = 0.2;

export const useFulfillmentDiffCheck = (fulfillmentDiffData?: FulfillmentDiffData) => {
    const { fulfillmentDiffDataStack, setFulfillmentDiffDataStack, orderFulfillmentProducts } = useFulfillmentContext();

    useDeepCompareEffect(() => {
        if (!fulfillmentDiffData) return;

        const { productId: id } = fulfillmentDiffData;
        setFulfillmentDiffDataStack(prev => ({ ...prev, [id]: fulfillmentDiffData }));

        return () => {
            setFulfillmentDiffDataStack(prev => {
                const { [id]: _, ...rest } = prev;
                return rest;
            });
        };
    }, [fulfillmentDiffData, setFulfillmentDiffDataStack]);

    const diffsExceedingThreshold = useMemo(() => {
        // using orderFulfillmentProducts to use the same sorting as in the UI
        const sortedDiffData = orderFulfillmentProducts.flatMap(product => {
            const diffData = fulfillmentDiffDataStack[product.id];
            return diffData ? diffData : [];
        });
        return sortedDiffData.filter(diffData => {
            const allowedDiff = diffData.originalAmount * allowedDiffThreshold;
            const realDiff = Math.abs(diffData.originalAmount - diffData.newAmount);
            return realDiff > allowedDiff;
        });
    }, [fulfillmentDiffDataStack, orderFulfillmentProducts]);

    const isAnyDiffExceedingThreshold = !!diffsExceedingThreshold.length;

    return {
        isAnyDiffExceedingThreshold,
        diffsExceedingThreshold,
    };
};
