import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DuplicateCheckHook, hasLineItemAllNeededData } from './useDuplicateCheck';
import { areDatelikeValuesAtSameDay } from '@/shared/utils/areDatelikeValuesAtSameDay';
import { getPartnerId, getUnit } from '../context/DuplicateCheckContext';

export const useDuplicateCheckCurrentForm: DuplicateCheckHook = () => {
    const { t } = useTranslation();
    return useCallback(
        (currentLineItem, { items, productDataMap }) => {
            const itemsToCompare = items
                .filter(item => item.groupKey !== currentLineItem.index)
                .filter(hasLineItemAllNeededData);
            if (!itemsToCompare.length) return;

            if (
                itemsToCompare.some(itemToCompare => {
                    const itemToComparePartnerId = getPartnerId(itemToCompare.productId, { productDataMap });
                    const itemToCompareUnit = getUnit(itemToCompare.productId, { productDataMap });
                    return (
                        currentLineItem.partnerId === itemToComparePartnerId &&
                        currentLineItem.amount === itemToCompare.amount &&
                        currentLineItem.referenceDocument === itemToCompare.referenceDocument &&
                        areDatelikeValuesAtSameDay(currentLineItem.serviceDate, itemToCompare.serviceDate) &&
                        currentLineItem.unit === itemToCompareUnit
                    );
                })
            ) {
                return {
                    message: t('product.lineItemListing.duplicateCheck.local'),
                };
            }
        },
        [t]
    );
};
