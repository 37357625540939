import { DatelikeInput, DatelikeSchema } from '@/shared/utils/DatelikeSchema';

export const areDatelikeValuesAtSameDay = (...dates: DatelikeInput[]) => {
    const hasNoDates = dates.every(date => !date);
    if (hasNoDates) return true;

    const datesAsDate = dates.map(date => DatelikeSchema.parse(date));
    const datesAsISODateString = datesAsDate.map(date => date.toISOString().slice(0, 10));

    return datesAsISODateString.every(date => date === datesAsISODateString[0]);
};
