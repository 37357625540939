import React, { useEffect } from 'react';
import { usePatchProductQuote, usePostProductQuote } from '@/CustomRequestProductChannel/clients/CustomRequestClient';
import { Price } from '@schuettflix/conversion';
import {
    DebouncedCustomRequestProduct,
    DebouncedCustomRequestProductProps,
    SimpleDebouncedCustomRequestProductProps,
} from '../../components/DebouncedCustomRequestProduct';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useOrder } from '@/clients/order/useOrder';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { usePositionQuote } from '@/CustomRequestProductChannel/hooks/usePositionQuote.ts';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { NestedOrderProductConfiguration } from '@schuettflix/interfaces';
import { environment } from '@/environment.ts';
import { usePatchOrderProductConfiguration } from '@/clients/orderProducts/orderProductsClient.ts';

export type OrderCustomRequestProductManagerProps = SimpleDebouncedCustomRequestProductProps & {
    orderId: string;
};

export const OrderCustomRequestProductManager: React.FC<OrderCustomRequestProductManagerProps> = ({
    orderId,
    ...props
}) => {
    const queryClient = useQueryClient();
    const taxInfo = useFormattedActiveTaxClassesByOrder(orderId);
    const platformOrganizationId = usePlatformOrganizationId();

    const { data: order } = useOrder(orderId);
    const { data: quote, isLoading } = useProductQuote(props.product.id);
    const { data: positionQuote } = usePositionQuote(props.product.positionProductId);

    const orderingOrganizationId = order?.customerInfo?.organizationId;

    async function invalidateAfterProductChange() {
        return Promise.all([
            queryClient.invalidateQueries({ queryKey: ['order-product-group', 'get', orderId] }),
            queryClient.invalidateQueries({ queryKey: ['custom-request', 'quote', props.product.id] }),
        ]);
    }

    const { postProductQuote } = usePostProductQuote();
    const { mutateAsync: mutateAsyncPostQuote, isPending: postQuoteIsPending } = useMutation({
        mutationKey: ['order-product-group', 'product-quote'],
        mutationFn: postProductQuote,
        onSuccess: invalidateAfterProductChange,
    });

    const { patchProductQuote } = usePatchProductQuote();
    const { mutateAsync: mutateAsyncPatchQuote, isPending: patchQuoteIsPending } = useMutation({
        mutationKey: ['order-product-group', 'product-quote'],
        mutationFn: patchProductQuote,
        onSuccess: invalidateAfterProductChange,
    });

    const { patchOrderProductConfiguration } = usePatchOrderProductConfiguration();
    const { mutateAsync: mutateAsyncPatchOrderProductConfiguration } = useMutation({
        mutationKey: ['patch-order-product-configuration', props.product.id],
        mutationFn: patchOrderProductConfiguration,
        onSuccess: invalidateAfterProductChange,
    });

    useEffect(() => {
        if (quote) {
            props.onCompleteChange(props.product.id, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quote]);

    if (!platformOrganizationId || !orderingOrganizationId) return null;

    const handleSubmit: DebouncedCustomRequestProductProps['onSubmit'] = async formValues => {
        if (patchQuoteIsPending || postQuoteIsPending) {
            return;
        }

        try {
            if (quote?.meta.id) {
                await mutateAsyncPatchQuote({
                    body: {
                        meta: {
                            orderingOrganizationId,
                            purchaseTaxClassId: formValues.purchaseTaxClassId,
                            salesTaxClassId: formValues.salesTaxClassId,
                            purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                            amount: formValues.amount,
                            unit: formValues.unit,
                            partnerOrganizationId: formValues.partnerOrganizationId,
                            currencyCode: formValues.currencyCode,
                            salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                            serviceDate: new Date(formValues.serviceDate),
                            callbackUrl: `${environment.ORDER_SERVICE_BASE_URL}/v1/product-quote`,
                        },
                        payload: {
                            name: formValues.productName,
                        },
                    },
                    quoteId: quote.meta.id,
                });
            } else {
                await mutateAsyncPostQuote({
                    meta: {
                        orderId,
                        orderingOrganizationId,
                        orderProductId: props.product.id,
                        orderProductGroupId: props.product.orderProductGroupId,
                        positionProductId: props.product.positionProductId,
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesTaxClassId: formValues.salesTaxClassId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        amount: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        currencyCode: formValues.currencyCode,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        serviceDate: new Date(formValues.serviceDate),
                        callbackUrl: `${environment.ORDER_SERVICE_BASE_URL}/v1/product-quote`,
                        productCategory: props.product.category,
                        productType: props.product.type,
                        platformOrganizationId,
                    },
                    payload: {
                        name: formValues.productName,
                    },
                });
            }

            props.onCompleteChange(props.product.id, true);
        } catch (err) {
            console.error('handleSubmit: ', err);
            props.onCompleteChange(props.product.id, false);
        }
    };

    const handleUpdateProductConfiguration = async (
        orderProductConfiguration: Partial<Omit<NestedOrderProductConfiguration, 'orderProductId'>> | null
    ) => {
        if (!orderProductConfiguration) return;

        await mutateAsyncPatchOrderProductConfiguration({
            orderProductId: props.product.id,
            orderProductConfiguration,
        });
    };

    return order?.customerInfo && !isLoading ? (
        <DebouncedCustomRequestProduct
            {...props}
            disabledFields={{ ...props.disabledFields, unit: !!order?.positionId || props.disabledFields?.unit }}
            quote={constructProductQuote({ taxInfo, quote, positionQuote, includePositionQuota: false })}
            customerId={orderingOrganizationId}
            taxInfo={taxInfo}
            onSubmit={handleSubmit}
            onUpdateProductConfiguration={handleUpdateProductConfiguration}
        />
    ) : null;
};
