import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useGetReferenceDocumentDuplicate } from '../clients/CustomRequestClient';

type GetReferenceDocumentDuplicateParams = Parameters<
    ReturnType<typeof useGetReferenceDocumentDuplicate>['getReferenceDocumentDuplicate']
>;

export const useReferenceDocumentDuplicateQueryOptions = () => {
    const { getReferenceDocumentDuplicate } = useGetReferenceDocumentDuplicate();
    return useCallback(
        (...params: GetReferenceDocumentDuplicateParams) =>
            ({
                queryKey: ['referenceDocumentDuplicateQuery', params],
                queryFn: () => getReferenceDocumentDuplicate(...params),

                // we can assume that the result of a duplicate check will not change during the session
                staleTime: Infinity,
            }) satisfies UseQueryOptions,
        [getReferenceDocumentDuplicate]
    );
};

export const useReferenceDocumentDuplicateQuery = (...params: GetReferenceDocumentDuplicateParams) => {
    return useQuery(useReferenceDocumentDuplicateQueryOptions()(...params));
};
