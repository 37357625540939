import React, { PropsWithChildren, StrictMode, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { ToasterContextProvider } from '@/shared/context/ToasterContext.tsx';
import { BrowserRouter, useNavigate } from 'react-router';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { environment } from '@/environment.ts';
import { initializeSentry } from '@/tools/sentry/initializeSentry.ts';
import { useTranslation } from 'react-i18next';
import { I18nProvider } from '@schuettflix/planum-react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { App } from '@/App.tsx';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SentryUserProvider } from '@/tools/sentry/SentryUserProvider.tsx';
import { IdTokenContextProvider } from '@/shared/context/IdTokenContext.tsx';
import { initializeUserflow } from '@/tools/userflow.ts';

const oktaAuth = new OktaAuth({
    issuer: 'https://schuettflix.okta.com/',
    clientId: environment.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email', 'groups'],
});

initializeSentry();
initializeUserflow();

function CoreProviders({ children }: PropsWithChildren) {
    const { i18n } = useTranslation();
    const { handleCacheErrors } = useQueryErrorHandler();

    const queryClient = useMemo(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    ...handleCacheErrors,
                }),
            }),
        [handleCacheErrors]
    );

    return (
        <I18nProvider locale={i18n.language}>
            <QueryClientProvider client={queryClient}>
                <IdTokenContextProvider>{children}</IdTokenContextProvider>
            </QueryClientProvider>
        </I18nProvider>
    );
}

function AuthenticationProvider({ children }: PropsWithChildren) {
    const navigate = useNavigate();

    const restoreOriginalUri = useMemo(
        () => (_oktaAuth: any, originalUri: string) => {
            void navigate(toRelativeUrl(originalUri || '/', window.location.origin));
        },
        [navigate]
    );

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
}

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <BrowserRouter>
            <AuthenticationProvider>
                <ToasterContextProvider>
                    <CoreProviders>
                        <SentryUserProvider />
                        <App />
                        <ReactQueryDevtools />
                    </CoreProviders>
                </ToasterContextProvider>
            </AuthenticationProvider>
        </BrowserRouter>
    </StrictMode>
);
