import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSubmitOrder as useSubmitOrderApi } from '@/clients/order/order';

export const useSubmitOrder = (orderId: string) => {
    const queryClient = useQueryClient();
    const { submitOrder } = useSubmitOrderApi();
    return {
        submitOrder: useMutation({
            mutationKey: ['submit-order'],
            mutationFn: ({ orderId }: { orderId: string }) => {
                return submitOrder(orderId);
            },
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['order', orderId] }),
        }),
    };
};
