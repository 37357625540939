import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import { usePosition } from '@/clients/position/usePosition.ts';
import { useMutation } from '@tanstack/react-query';
import { Button, DatePicker } from '@schuettflix/planum-react';
import { MessageBox, Textarea, ToggleSwitch } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { getDatePickerValue } from './utils/getDatePickerValue.ts';
import { validateWithConstructionProjectDateRange } from './utils/validateWithConstructionProjectDateRange.ts';
import { useConstructionProject } from '@/clients/constructionProject/useConstructionProject.ts';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { usePatchPosition } from '@/clients/position/position.ts';

export interface PositionSettingsProps extends Pick<PositionSection, 'onMoveToNextSection'> {
    positionId: string;
}

export const PositionSettings: React.FC<PositionSettingsProps> = ({ positionId, onMoveToNextSection }) => {
    const { t } = useTranslation();
    const { data: position } = usePosition(positionId);
    const { data: constructionProject } = useConstructionProject(position?.customerInfo.constructionProjectId);
    const { isEditing } = useEditPositionState();
    const { formatDate } = useFormatters();

    const { patchPosition } = usePatchPosition();
    const { mutateAsync: mutateAsyncPatchPosition, isPending: isMutateAsyncPatchPositionIsPending } = useMutation({
        mutationKey: ['patch-position', positionId],
        mutationFn: patchPosition,
        throwOnError: true,
    });

    const PositionSettingFormSchema = z.object({
        active: z.boolean({
            required_error: t('product.addPosition.errorMessage.pleaseEnterValue'),
        }),
        validFrom: z.coerce
            .date({
                required_error: t('product.addPosition.errorMessage.pleaseEnterValue'),
            })
            .refine(
                validateWithConstructionProjectDateRange(constructionProject),
                t('position.settings.errorMessage.pleaseEnterValidStartDate')
            ),
        validTo: z.coerce
            .date({
                required_error: t('product.addPosition.errorMessage.pleaseEnterValue'),
            })
            .refine(
                validateWithConstructionProjectDateRange(constructionProject),
                t('position.settings.errorMessage.pleaseEnterValidEndDate')
            ),
        note: z.string().optional().nullable(),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        getValues,
        control,
    } = useZodForm({
        schema: PositionSettingFormSchema,
        defaultValues: {
            active: position?.active ?? false,
            validFrom: position?.validFrom ? position?.validFrom : undefined,
            validTo: position?.validTo ? position?.validTo : undefined,
            note: position?.note ? position?.note : undefined,
        },
    });

    const formValues = getValues();
    const isPositionInactive = new Date(formValues.validFrom) > new Date();

    useEffect(() => {
        if (isPositionInactive) {
            setValue('active', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPositionInactive]);

    const getDatePickerErrorMessage = () => {
        if (errors['validFrom'] && errors['validTo'] && errors['validTo']?.type === 'custom') {
            return t('position.settings.errorMessage.pleaseEnterValidDateRange');
        }

        if (errors['validFrom']) {
            return errors['validFrom']?.message;
        }

        if (errors['validTo']) {
            return errors['validTo']?.message;
        }
    };

    const onSubmit = async () => {
        const values = getValues();
        const isValid = PositionSettingFormSchema.safeParse(values).success;

        if (!isValid) return;

        await mutateAsyncPatchPosition({
            body: { ...values },
            positionId,
        })
            .then(() => onMoveToNextSection?.())
            .catch(console.error);
    };

    if (!constructionProject || !position) {
        return;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-[720px]">
            <h2 className="font-headline-lg mb-6">{`3. ${t('position.sections.positionSettings.title')}`}</h2>
            <div className="flex w-[720px] flex-col gap-[40px]">
                <MessageBox>
                    {t(`position.sections.positionSettings.projectValidityRange`, {
                        startDate: formatDate(new Date(constructionProject.validFrom)),
                        endDate: formatDate(new Date(constructionProject.validTo)),
                        projectTitle: constructionProject.name,
                    })}
                </MessageBox>
                <div className="flex justify-between">
                    <div className="flex w-full flex-col">
                        {/*TODO: add proper form error handling for DatePicker component in planum-react lib*/}
                        <DatePicker
                            label={t('position.sections.positionSettings.datePicker.label')}
                            range={true}
                            isDateUnavailable={date =>
                                !validateWithConstructionProjectDateRange(constructionProject)(
                                    new Date(date.toString())
                                )
                            }
                            value={getDatePickerValue(formValues.validFrom, formValues.validTo)}
                            onChange={dateRange => {
                                const { start, end } = dateRange;

                                setValue('validFrom', new Date(start.toString()), {
                                    shouldValidate: true,
                                });
                                setValue('validTo', new Date(end.toString()), {
                                    shouldValidate: true,
                                });
                            }}
                        />
                        {(errors['validFrom'] || errors['validTo']) && (
                            <p className="font-copy-sm text-critical mt-4">{getDatePickerErrorMessage()}</p>
                        )}
                    </div>

                    <div className="flex w-full items-center justify-end">
                        <Controller
                            name="active"
                            control={control}
                            render={({ field: { ref: _, onChange: _onChange, ...field } }) => (
                                <ToggleSwitch
                                    {...field}
                                    checked={field.value}
                                    label={t('position.settings.state.label')}
                                    size="md"
                                    disabled={isPositionInactive}
                                    onChange={value => {
                                        setValue('active', value);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-copy-sm-strong">{t('position.sections.positionSettings.note.title')}</span>
                    <Textarea
                        {...register('note')}
                        placeholder={t('position.sections.positionSettings.note.placeHolder')}
                        rows={5}
                    />
                </div>
                <footer className="flex max-w-[720px] flex-col items-center justify-center">
                    <Button
                        isLoading={isMutateAsyncPatchPositionIsPending}
                        className="font-copy-md"
                        stature="md"
                        type="submit"
                        prominence={isEditing ? 'secondary' : 'primary'}
                    >
                        {t('position.settings.summaryButton')}
                    </Button>
                </footer>
            </div>
        </form>
    );
};
