import * as Sentry from '@sentry/react';
import { environment } from '@/environment.ts';

export const initializeSentry = () => {
    Sentry.init({
        dsn: 'https://aacabb2ceee9e0e1f2ccc9caec7344ec@o4507349918810112.ingest.de.sentry.io/4507350586032208',
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
            }),
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.httpClientIntegration(),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.2,
        profilesSampleRate: 0.2,
        environment: environment.SENTRY_ENVIRONMENT,
    });
};
