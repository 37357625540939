import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    useCreateProductSelectionApi,
    useDeleteProductSelectionApi,
} from '@/clients/orderProducts/orderProductsClient.ts';
import { PRODUCT_CATEGORY, PRODUCT_TYPES } from '@schuettflix/interfaces';

export const useCreateOrderProduct = () => {
    const { createProductSelection } = useCreateProductSelectionApi();

    return {
        createOrderProduct: useMutation({
            mutationKey: ['order-product', 'select'],
            mutationFn: ({
                type,
                productGroupId,
                configurationId,
                index,
                templateId,
                category,
            }: {
                type: PRODUCT_TYPES;
                productGroupId: string;
                configurationId?: string;
                index?: number | undefined;
                templateId?: string;
                category: PRODUCT_CATEGORY;
            }) => {
                return createProductSelection(type, category, productGroupId, configurationId, index, templateId);
            },
        }),
    };
};

export const useDeleteOrderProduct = (orderId: string) => {
    const queryClient = useQueryClient();
    const { deleteProductSelection } = useDeleteProductSelectionApi();

    return {
        deleteOrderProduct: useMutation({
            mutationKey: ['order-product', 'delete-product'],
            mutationFn: ({ orderProductId }: { orderProductId: string }) => {
                return deleteProductSelection(orderProductId);
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['order-product-group', 'get', orderId],
                });
            },
        }),
    };
};
