import { calculateTakeRatePercentage } from '@/shared/utils/calculateTakeRatePercentage.ts';
import { groupBy } from '@/shared/utils';
import { Price } from '@schuettflix/conversion';
import type { ProductWithPurifiedWeakQuote } from '../components/ProductGroupBalance';

const DUMMY_CURRENCY = 'EUR';

const getTotalPrice = (products: ProductWithPurifiedWeakQuote[], priceType: 'purchasePrice' | 'salesPrice'): Price => {
    return products.reduce(
        (total, product) => {
            const price = new Price(product.quote[priceType], DUMMY_CURRENCY);
            const productTotal = price.multiply(product.quote.amount);
            return total.add(productTotal);
        },
        new Price(0, DUMMY_CURRENCY)
    );
};

interface BalanceSheetCalculationResult {
    customerPrice: number;
    takeRate: number | null;
    materialPrice: number | null;
    transportPrice: number | null;
    servicePrice: number | null;
}

export const useBalanceSheetCalculations = (
    products: ProductWithPurifiedWeakQuote[]
): BalanceSheetCalculationResult => {
    const productsByCategory = groupBy(products, 'category');

    const stats = {
        revenue: 0,
        costs: 0,
    };

    products.map(product => {
        if (Math.sign(product.quote.purchasePrice) === -1) {
            stats.revenue += Math.abs(product.quote.purchasePrice) * product.quote.amount;
        } else {
            stats.costs += product.quote.purchasePrice * product.quote.amount;
        }

        if (Math.sign(product.quote.salesPrice) === -1) {
            stats.costs += Math.abs(product.quote.salesPrice) * product.quote.amount;
        } else {
            stats.revenue += product.quote.salesPrice * product.quote.amount;
        }

        return stats;
    });

    const materialPrice = productsByCategory.MATERIAL
        ? getTotalPrice(productsByCategory.MATERIAL, 'purchasePrice').toNumber()
        : null;
    const transportPrice = productsByCategory.TRANSPORT
        ? getTotalPrice(productsByCategory.TRANSPORT, 'purchasePrice').toNumber()
        : null;
    const serviceProducts = (productsByCategory.MATERIAL_SERVICE ?? []).concat(
        productsByCategory.TRANSPORT_SERVICE ?? []
    );
    const servicePrice = serviceProducts.length ? getTotalPrice(serviceProducts, 'purchasePrice').toNumber() : null;

    const totalSalesPrice = getTotalPrice(products, 'salesPrice');

    const takeRate = calculateTakeRatePercentage(stats.revenue, stats.costs);

    return {
        customerPrice: totalSalesPrice.toNumber(),
        takeRate: takeRate,
        materialPrice: materialPrice,
        transportPrice: transportPrice,
        servicePrice: servicePrice,
    };
};
