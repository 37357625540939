import {
    FilterOptionsConstructionProject,
    GetConstructionProjectListResponseSchema,
    GetConstructionProjectResponse,
    GetConstructionProjectResponseSchema,
    GetConstructionProjectsListResponse,
} from './types/constructionProject';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export function useGetConstructionProjectListByOrganizationIdAndStatusIn() {
    const { constructionProjectClient } = useHttpClientContext();
    return {
        getConstructionProjectListByOrganizationIdAndStatusIn: async (
            organizationId: number,
            filterOptions: FilterOptionsConstructionProject
        ) => {
            return constructionProjectClient
                .get<GetConstructionProjectsListResponse>(
                    `/admin/v1/organizations/${organizationId}/construction-projects`,
                    {
                        params: filterOptions,
                    }
                )
                .then(res => GetConstructionProjectListResponseSchema.parse(res.data));
        },
    };
}

export function useGetConstructionProjectById() {
    const { constructionProjectClient } = useHttpClientContext();
    return {
        getConstructionProjectById: (id: string) =>
            constructionProjectClient
                .get<GetConstructionProjectResponse>(`/admin/v1/construction-projects/${id}`)
                .then(res => GetConstructionProjectResponseSchema.parse(res.data)),
    };
}
