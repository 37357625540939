import { useSellerProducts } from '@/clients/sellerProducts/useSellerProduct.ts';
import { LoadingSpinner } from '@schuettflix/react-components';
import { EditFactoryProductForm } from '@/modules/supplierProductManagement/EditFactoryProductForm.tsx';

export function EditFactoryProductList({ factoryId }: { factoryId: number }) {
    const { data: sellerProductsData, isLoading } = useSellerProducts(factoryId);

    const sellerProducts = sellerProductsData?.items || [];

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <ul className="flex flex-col gap-4">
                {sellerProducts.map(product => (
                    <li key={product.id}>
                        <div className={'shadow-low rounded-[4px] bg-white p-6'}>
                            <h3 className="font-headline-lg-strong pb-4">{product.name}</h3>
                            <EditFactoryProductForm product={product} factoryId={factoryId} />
                        </div>
                    </li>
                ))}
                {sellerProducts.length === 0 && <li>No products at this site</li>}
            </ul>
        </>
    );
}
