import {
    FulfillmentContextProvider,
    useFulfillmentContext,
} from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { ClientInformationFormFulfillment } from '@/modules/fulfillment/components/ClientInformationFormFulfillment.tsx';
import { Summary } from '@/modules/fulfillment/components/Summary.tsx';
import { useCancelOrderMutation } from '@/clients/order/useCancelOrderMutation.ts';
import { useFulfillOrderMutation } from '@/clients/order/useFulfillOrderMutation.ts';
import { ErrorBoundary } from '@/shared/components/ErrorBoundary.tsx';
import { ReloadIcon } from '@schuettflix/icons-react';
import { Button, LoadingSpinner, MessageBox, Tag, TagProps } from '@schuettflix/react-components';
import React, { Fragment, ReactNode, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { DocumentUploadSection } from '@/modules/fulfillment/components/DocumentUploadSection.tsx';
import { ProductSelectionSection } from '@/modules/fulfillment/components/ProductSelectionSection.tsx';
import { useOrderStatus } from '@/clients/fulfillment/useOrderStatus.ts';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { useQueryClient } from '@tanstack/react-query';
import { ORDER_PROCESS_STATUS, SUPPORTED_UNIT_LABEL } from '@schuettflix/interfaces';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';
import { PageHeader } from '@/shared/components/PageHeader.tsx';
import { encodeBase64Json } from '@/shared/utils/base64Json.ts';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId.ts';
import { cn } from '@/shared/utils/cn.ts';
import { FullscreenLoadingSuspenseBoundary } from '@/shared/components/FullscreenLoadingSuspenseBoundary.tsx';
import { BeforeUnloadContext, BeforeUnloadContextProvider } from '@/shared/context/BeforeUnloadContext.tsx';
import { FulfillmentUploadProvider } from './context/FulfillmentUploadContext';
import { useFulfillmentDiffCheck } from './hooks/useFulfillmentDiffCheck';
import { Modal, ModalTrigger, Button as PlanumButton } from '@schuettflix/planum-react';

type NonEmptyArray<T> = [T, ...T[]];

export const FulfillmentPage: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const { t } = useTranslation();

    if (!orderId) {
        return <div>{t('fulfillment.error.noOrderId')}</div>;
    }

    return (
        <>
            <DocumentTitle title={t('page.fulfillment.documentTitle')} />
            <ErrorBoundary>
                <FullscreenLoadingSuspenseBoundary>
                    <FulfillmentContextProvider orderId={orderId}>
                        <FulfillmentUploadProvider>
                            <BeforeUnloadContextProvider defaultEnabled>
                                <FulfillmentWizardWrapper orderId={orderId} />
                            </BeforeUnloadContextProvider>
                        </FulfillmentUploadProvider>
                    </FulfillmentContextProvider>
                </FullscreenLoadingSuspenseBoundary>
            </ErrorBoundary>
        </>
    );
};

const orderStatusReason = {
    COMPLETED: 'fulfillment.footer.fulfilled.title',
    CANCELED: 'fulfillment.footer.cancelled.title',
} as const satisfies Record<Exclude<ORDER_PROCESS_STATUS, 'NEW' | 'IN_PROGRESS'>, string>;

const tagLabel = {
    NEW: 'fulfillment.header.tag.new.label',
    IN_PROGRESS: 'fulfillment.header.tag.open.label',
    COMPLETED: 'fulfillment.header.tag.completed.label',
    CANCELED: 'fulfillment.header.tag.canceled.label',
} as const satisfies Record<ORDER_PROCESS_STATUS, string>;

const tagType: Record<ORDER_PROCESS_STATUS, TagProps['type']> = {
    NEW: 'warning',
    IN_PROGRESS: 'warning',
    COMPLETED: 'positive',
    CANCELED: 'error',
};

interface FulfillmentWizardWrapperProps {
    orderId: string;
}

const FulfillmentWizardWrapper: React.FC<FulfillmentWizardWrapperProps> = ({ orderId }) => {
    const {
        order,
        products,
        documents,
        errors,
        setSubmitted,
        isSubmitted,
        isPageValid,
        canSubmit,
        getLineItemListingWithProductFallback,
        lineItemListing,
    } = useFulfillmentContext();

    const { t } = useTranslation();
    const { data: orderStatus } = useOrderStatus(orderId);

    const { formatDate, formatNumber } = useFormatters();
    const queryClient = useQueryClient();
    const beforeUnloadContext = useContext(BeforeUnloadContext);

    const { openUrl: redirectToMonolith } = useMonolithFrontendUrl({
        path: '/dashboard',
        target: 'currentTab',
        historyMethod: 'push',
        query: {
            order: encodeBase64Json({ search: order?.humanReadableId }),
            'conditional-redirect': encodeBase64Json({
                to: '/order-management/orders',
                conditions: [
                    {
                        featureFlag: 'monolith-display-generic-order',
                        is: true,
                    },
                ],
            }),
        },
    });

    const platformOrganizationId = usePlatformOrganizationId();

    const { mutate: fulfillmentMutation, isPending: fulfillmentMutationIsPending } = useFulfillOrderMutation(orderId);
    const { mutate: cancelOrderMutation, isPending: cancelOrderMutationisPending } = useCancelOrderMutation();

    const isLoading = fulfillmentMutationIsPending || cancelOrderMutationisPending;
    const [orderClosed, setOrderClosed] = useState(false);

    const isFulfilled = orderStatus?.orderStatus === 'COMPLETED';
    const isCancelled = orderStatus?.orderStatus === 'CANCELED';
    const isClosed = isFulfilled || isCancelled;

    const closedReason = useMemo(() => {
        if (orderStatus?.closedAt) {
            return t(orderStatusReason[isFulfilled ? 'COMPLETED' : 'CANCELED'], {
                date: formatDate(orderStatus.closedAt),
            });
        }
        return '';
    }, [orderStatus?.closedAt, formatDate, t, isFulfilled]);

    useEffect(() => {
        if (!errors?.length || !isSubmitted) {
            return;
        }

        setTimeout(() => {
            errors[0]?.scrollToElement();
        }, 300);
    }, [errors, isSubmitted]);

    useEffect(() => {
        if (orderStatus?.orderStatus === 'IN_PROGRESS') {
            setOrderClosed(true);
        }
    }, [orderStatus?.orderStatus, setOrderClosed]);

    const { isAnyDiffExceedingThreshold, diffsExceedingThreshold } = useFulfillmentDiffCheck();
    const [isDiffCheckModalOpen, setIsDiffCheckModalOpen] = useState(false);

    const fulfillOrder = useCallback(() => {
        beforeUnloadContext.setEnabled(false);
        setSubmitted(true);

        if (!isPageValid) {
            return errors[0]?.scrollToElement();
        }

        if (!isDiffCheckModalOpen && isAnyDiffExceedingThreshold) {
            return setIsDiffCheckModalOpen(true);
        }

        fulfillmentMutation(
            {
                clientInformation: {
                    userId: order!.customerInfo!.userId,
                    manualCostCenter: order!.customerInfo!.manualCostCenter || '',
                    projectCode: order!.customerInfo!.projectCode,
                },
                products: products
                    .filter(product => product.quote !== undefined)
                    .map(product => ({
                        id: product.id,
                        quoteId: product.quote!.id,
                        name: product.quote!.name,
                        amount: product.quote!.amount,
                        unit: product.quote!.unit,
                        currencyCode: product.quote!.currencyCode,
                        partnerOrganizationId: product.quote!.partnerOrganizationId!,
                        orderingOrganizationId: product.quote!.orderingOrganizationId,
                        platformOrganizationId: product.quote!.platformOrganizationId,
                        purchasePrice: product.quote!.purchasePrice.toNumber(),
                        purchaseTaxClassId: product.quote!.purchaseTaxClassId,
                        salesPrice: product.quote!.salesPrice.toNumber(),
                        salesTaxClassId: product.quote!.salesTaxClassId,
                        serviceDate: new Date(product.quote!.serviceDate),
                    })),
                supportingDocuments: documents.map(document => ({
                    description: 'Invoice Document',
                    urlPath: document.url,
                    fileName: document.fileName,
                })) as NonEmptyArray<{ description: string; urlPath: string; fileName: string }>,
                lineItemListing: getLineItemListingWithProductFallback(),
                fulfilledWithIndividualItemListing: !!lineItemListing,
            },
            {
                onSuccess: () => {
                    redirectToMonolith({
                        toast: {
                            message: t('fulfillment.toast.fulfillmentSuccessful.message'),
                            type: 'success',
                        },
                        query: {
                            orderForced: encodeBase64Json({ status: ['closed'] }),
                        },
                    });
                },
            }
        );
    }, [
        beforeUnloadContext,
        setSubmitted,
        isPageValid,
        isDiffCheckModalOpen,
        isAnyDiffExceedingThreshold,
        fulfillmentMutation,
        order,
        products,
        documents,
        getLineItemListingWithProductFallback,
        lineItemListing,
        errors,
        redirectToMonolith,
        t,
    ]);

    const cancelOrder = useCallback(() => {
        beforeUnloadContext.setEnabled(false);

        cancelOrderMutation(orderId, {
            onSuccess: () => {
                redirectToMonolith({
                    toast: {
                        message: t('fulfillment.toast.cancelOrderSuccessful.message'),
                        type: 'success',
                    },
                    query: {
                        orderForced: encodeBase64Json({ status: ['canceled'] }),
                    },
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelOrderMutation, orderId, queryClient, t, redirectToMonolith]);

    if (!order || !platformOrganizationId) {
        return;
    }

    const tagPropsFromStatus = (orderStatus: ORDER_PROCESS_STATUS): TagProps => ({
        label: t(tagLabel[orderStatus]),
        type: tagType[orderStatus],
        subdued: true,
    });

    return (
        <div className="bg pb-[80px]">
            <PageHeader
                title={t('fulfillment.header.title', { orderId: order.humanReadableId })}
                additionalContent={orderStatus && <Tag {...tagPropsFromStatus(orderStatus.orderStatus)} />}
            />
            {!!order && (
                <main className="py-18 flex h-full w-full flex-col items-center gap-[40px] overflow-y-auto [counter-reset:section]">
                    <MessageBox title={t('fulfillment.messageBox.title')} className="w-[720px]">
                        <p>{t('fulfillment.messageBox.text')}</p>
                    </MessageBox>

                    <FulfillmentSection title={t('fulfillment.clientInformation.title')}>
                        <ClientInformationFormFulfillment disabled={isClosed || orderClosed} />
                    </FulfillmentSection>

                    <FulfillmentSection title={t('fulfillment.products.title')} size="lg">
                        <ProductSelectionSection disabled={isClosed || orderClosed} />
                    </FulfillmentSection>

                    <FulfillmentSection title={t('fulfillment.documents.title')}>
                        <DocumentUploadSection disabled={isClosed || orderClosed} />
                    </FulfillmentSection>

                    <FulfillmentSection title={t('fulfillment.summary.title')}>
                        <ErrorBoundary>
                            <Suspense fallback={<LoadingSpinner block />}>
                                <Summary orderId={orderId} disabled={isClosed || orderClosed} />
                            </Suspense>
                        </ErrorBoundary>
                    </FulfillmentSection>
                </main>
            )}
            <footer className="bg border-t-divider shadow-high fixed bottom-0 left-0 right-0 z-20 flex h-[80px] items-center justify-center">
                {!isClosed && (
                    <div className="flex w-full max-w-[720px] items-center justify-between">
                        <Button
                            label={t('fulfillment.footer.cancelOrderButton.label')}
                            variant="secondary"
                            size="sm"
                            leadingIcon={ReloadIcon}
                            className="!bg-light-gray-200 border-none !px-0 hover:underline"
                            disabled={isLoading || orderClosed || !canSubmit}
                            onClick={cancelOrder}
                        />
                        <Button
                            label={t('fulfillment.footer.fulfillOrderButton.label')}
                            variant="primary"
                            size="sm"
                            disabled={isLoading || orderClosed || !canSubmit}
                            onClick={fulfillOrder}
                            data-test="fulfill-order-button"
                        />
                    </div>
                )}

                {closedReason && <p className="font-copy-lg">{closedReason}</p>}
            </footer>
            <ModalTrigger isOpen={isDiffCheckModalOpen} onOpenChange={setIsDiffCheckModalOpen}>
                <Modal>
                    {({ close }) => (
                        <>
                            <Modal.Heading onAbort={close}>{t('fulfillment.diffCheck.heading')}</Modal.Heading>
                            <Modal.Content className="grid grid-cols-[1fr_auto_auto] gap-x-4 gap-y-2">
                                <div className="font-copy-md-strong">
                                    {t('fulfillment.diffCheck.productNameHeader')}
                                </div>
                                <div className="font-copy-md-strong">
                                    {t('fulfillment.diffCheck.originalAmountHeader')}
                                </div>
                                <div className="font-copy-md-strong">{t('fulfillment.diffCheck.newAmountHeader')}</div>
                                {diffsExceedingThreshold.map(diffData => (
                                    <Fragment key={diffData.productId}>
                                        <div className="font-copy-md">{diffData.productName}</div>
                                        <div className="font-copy-md">
                                            {formatNumber(diffData.originalAmount)}{' '}
                                            {t(SUPPORTED_UNIT_LABEL[diffData.unit])}
                                        </div>
                                        <div className="font-copy-md">
                                            {formatNumber(diffData.newAmount)} {t(SUPPORTED_UNIT_LABEL[diffData.unit])}
                                        </div>
                                    </Fragment>
                                ))}
                            </Modal.Content>
                            <Modal.Actions>
                                <PlanumButton onPress={close} prominence="secondary">
                                    {t('fulfillment.diffCheck.declineButtonLabel')}
                                </PlanumButton>
                                <PlanumButton
                                    onPress={() => {
                                        fulfillOrder();
                                        close();
                                    }}
                                >
                                    {t('fulfillment.diffCheck.acceptButtonLabel')}
                                </PlanumButton>
                            </Modal.Actions>
                        </>
                    )}
                </Modal>
            </ModalTrigger>
        </div>
    );
};

interface FulfillmentSectionProps {
    title: string;
    size?: 'sm' | 'lg';
    children: ReactNode;
}

const FulfillmentSection: React.FC<FulfillmentSectionProps> = ({ title, size = 'sm', children }) => {
    return (
        <div
            className={cn('flex flex-col items-center', {
                'w-[720px]': size === 'sm',
                'w-[1280px]': size === 'lg',
            })}
        >
            <h2 className="font-headline-lg mb-[40px] w-full max-w-[720px] [counter-increment:section] before:content-[counter(section)'.\0020']">
                {title}
            </h2>
            <div className="w-full">{children}</div>
            <hr className="border-divider mt-[40px] w-[720px]" />
        </div>
    );
};
