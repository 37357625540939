import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    useDeletePositionProductGroup as useDeletePositionProductGroupClient,
    useGetPositionProductGroups as useGetPositionProductGroupsClient,
    useUpdatePositionProductGroup as useUpdatePositionProductGroupClient,
} from './position';
import { z } from 'zod';
import { PatchPositionGroupRequestSchema } from '@schuettflix/interfaces';

export function getPositionProductGroupsQueryKey(positionId: string) {
    return ['positionProductGroups', positionId];
}

export const usePositionProductGroups = (positionId: string) => {
    const { getPositionProductGroups } = useGetPositionProductGroupsClient();

    return useQuery({
        queryKey: getPositionProductGroupsQueryKey(positionId),
        queryFn: () => getPositionProductGroups(positionId),
        throwOnError: true,
    });
};

export const useDeletePositionProductGroup = (positionId: string) => {
    const queryClient = useQueryClient();
    const { deletePositionProductGroup } = useDeletePositionProductGroupClient();

    return useMutation({
        mutationFn: ({ productGroupId }: { productGroupId: string }) =>
            deletePositionProductGroup(positionId, productGroupId),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};

export const useUpdatePositionProductGroup = (positionId: string) => {
    const queryClient = useQueryClient();
    const { updatePositionProductGroup } = useUpdatePositionProductGroupClient();

    return useMutation({
        mutationFn: ({
            productGroupId,
            productGroup,
        }: {
            productGroupId: string;
            productGroup: z.infer<typeof PatchPositionGroupRequestSchema>;
        }) => updatePositionProductGroup(positionId, productGroupId, productGroup),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};
