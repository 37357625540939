import { useMutation } from '@tanstack/react-query';
import { useCancelOrder } from './order';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';

export const useCancelOrderMutation = () => {
    const { handleMutationErrors } = useQueryErrorHandler();
    const { cancelOrder } = useCancelOrder();

    return useMutation({
        ...handleMutationErrors,
        mutationFn: cancelOrder,
    });
};
