import { PrefillTemplate } from '@/shared/types/prefill-template-data.ts';
import { TEMPLATE_GROUP_TITLE, TEMPLATE_GROUP_TITLE_LABEL } from '@schuettflix/interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useCreateProductGroupApi } from '@/clients/orderProducts/orderProductsClient';
import { useCreateOrderProduct } from '@/clients/orderProducts/useOrderProduct';

export function useCreateOrderProductGroupsByTemplate(orderId: string) {
    const { t } = useTranslation();
    const { createOrderProduct } = useCreateOrderProduct();
    const queryClient = useQueryClient();

    const { createProductGroup } = useCreateProductGroupApi();

    const createProductGroups = async (template: PrefillTemplate) => {
        for (const [index, templateGroup] of template.productGroups.entries()) {
            const createdProductGroup = await createProductGroup({
                orderId,
                productGroupName: t(TEMPLATE_GROUP_TITLE_LABEL[templateGroup.name as TEMPLATE_GROUP_TITLE], ''),
                index,
                templateId: template.templateId,
                type: template.type,
            });
            for (const [index, templateProductPosition] of templateGroup.orderProducts.entries()) {
                await createOrderProduct.mutateAsync({
                    type: templateProductPosition.type,
                    productGroupId: createdProductGroup.id,
                    index: index + 1,
                    templateId: template.templateId,
                    category: templateProductPosition.category,
                });
            }
        }

        await queryClient.invalidateQueries({ queryKey: ['order-product-group', 'get', orderId] });
    };

    return useMutation({
        mutationKey: ['create-product-groups-by-template'],
        mutationFn: createProductGroups,
    });
}
