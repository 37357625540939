import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { useCommitEditPosition as useCommitEditPositionClient } from './position.ts';

export function useCommitEditPosition(positionId: string, temporaryId: string) {
    const { handleMutationErrors } = useQueryErrorHandler();
    const { commitEditPosition } = useCommitEditPositionClient();

    return useMutation({
        ...handleMutationErrors,
        mutationKey: ['commit-edit-position', positionId],
        mutationFn: () => commitEditPosition(positionId, temporaryId),
        throwOnError: true,
    });
}
