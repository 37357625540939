import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { csCZ, deAT, deDE, enGB, plPL } from '@schuettflix/i18n/src/frontend.ts';

export const resources = {
    'en-GB': {
        translation: enGB,
    },
    'de-AT': {
        translation: deAT,
    },
    'de-DE': {
        translation: deDE,
    },
    'cs-CZ': {
        translation: csCZ,
    },
    'pl-PL': {
        translation: plPL,
    },
} as const;

export const i18n = createInstance({
    lng: 'en-GB',
    debug: true,
    defaultNS: 'translation',
    interpolation: {
        escapeValue: false,
    },
    fallbackLng: {
        'de-AT': ['de-DE', 'en-GB'],
        'pl-PL': ['en-GB', 'de-DE'],
        'cs-CZ': ['en-GB', 'de-DE'],
        default: ['en-GB'],
    },
    resources,
});
void i18n.use(initReactI18next).init();
