import { User } from './Users';
import { FilterOptionsUser } from './types/users';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useFilterUsers = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        filterUsers: (filterOptions: FilterOptionsUser) =>
            monolithClient.get<{ items: User[] }>('/v2/user', { params: filterOptions }).then(res => res.data),
    };
};

export const useGetUserById = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getUserById: (id: number) => monolithClient.get<User>(`/v2/user/${id}`).then(res => res.data),
    };
};

export * from './types/users';
