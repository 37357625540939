import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { FilterOptionsUser, useFilterUsers as useFilterUsersClient, useGetUserById, User } from './Users';

export function useFilterUsers(filterOptions: FilterOptionsUser) {
    const { filterUsers } = useFilterUsersClient();

    return useQuery({
        queryKey: ['users', filterOptions],
        queryFn: () => filterUsers(filterOptions),
    });
}

export function useUser(id?: number | null, options?: UseQueryOptions<User>) {
    const { getUserById } = useGetUserById();

    return useQuery({
        queryKey: ['user', id!],
        queryFn: () => getUserById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
        ...options,
    });
}

export function useUserSuspenseQuery(id?: number | null) {
    const { getUserById } = useGetUserById();

    return useSuspenseQuery({
        queryKey: ['user', id],
        queryFn: () => (id ? getUserById(id) : null),
        refetchOnWindowFocus: false,
    });
}
