import { i18n } from '@/tools/i18n/config.ts';
import React from 'react';
import { setUserLocaleToLocalStorage } from '@/shared/utils/localStorage.ts';
import { Menu } from '@headlessui/react';
import { GlobeIcon } from '@schuettflix/icons-react';
import { autoUpdate, flip, useFloating } from '@floating-ui/react';
import { cn } from '@/shared/utils/cn.ts';

export const LanguageSwitcher = () => {
    const handleChangeLanguage = (language: string) => {
        void i18n.changeLanguage(language);
        setUserLocaleToLocalStorage(language);
    };

    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-end',
        strategy: 'fixed',
        middleware: [flip()],
        whileElementsMounted: autoUpdate,
    });

    const languages = [
        {
            label: 'Čeština',
            locale: 'cs-CZ',
        },
        {
            label: 'Deutsch',
            locale: 'de-DE',
        },
        {
            label: 'English',
            locale: 'en-GB',
        },
        {
            label: 'Polski',
            locale: 'pl-PL',
        },
    ];

    return (
        <Menu as="span">
            <Menu.Button className="flex shrink cursor-pointer gap-2 p-4" ref={refs.setReference}>
                <GlobeIcon size="sm" />
            </Menu.Button>
            <Menu.Items
                as="div"
                style={floatingStyles}
                ref={refs.setFloating}
                className="card shadow-high z-10 w-[280px] rounded p-0"
            >
                {languages.map(languageConfig => (
                    <Menu.Item
                        key={languageConfig.locale}
                        as="div"
                        onClick={() => handleChangeLanguage(languageConfig.locale)}
                        className={cn(
                            'font-copy-md w-full cursor-pointer items-center p-4 first-of-type:rounded-t last-of-type:rounded-b',
                            {
                                'hover:bg-surface-disabled': i18n.language !== languageConfig.locale,
                                'bg-surface-selected': i18n.language === languageConfig.locale,
                            }
                        )}
                    >
                        {languageConfig.label}
                    </Menu.Item>
                ))}
            </Menu.Items>
        </Menu>
    );
};
