import { LDContext, LDProvider } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useMemo } from 'react';
import { environment } from '@/environment.ts';
import { JWTPayload } from '@okta/okta-auth-js';

type FeatureFlagProviderProps = PropsWithChildren & Pick<JWTPayload, 'email' | 'name'>;

export const FeatureFlagProvider = ({ children, email, name }: FeatureFlagProviderProps) => {
    const context = useMemo<LDContext>(
        () => ({
            kind: 'user',
            key: email || 'anonymous',
            name,
            email,
        }),
        [email, name]
    );

    return (
        <LDProvider
            reactOptions={{ useCamelCaseFlagKeys: false }}
            clientSideID={environment.LAUNCH_DARKLY_CLIENT_ID}
            deferInitialization={true}
            context={context}
        >
            {children}
        </LDProvider>
    );
};
