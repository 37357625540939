import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

interface GrainSize {
    min: number;
    max: number;
    unit: string;
}

interface ImageUrl {
    original: string;
    small: string;
    medium: string;
    large: string;
    thumbnail: string;
    logo: string;
}

interface Image {
    fileName: string;
    uuid: string;
    url: ImageUrl;
}

interface ProductCategory {
    id: string;
    label: string;
    image: Image;
}

export interface SellerProduct {
    id: string;
    masterProductId: string;
    factoryId: number;
    grainSize: GrainSize;
    name: string;
    price: string;
    minimumGuaranteedAmount: number;
    productCategories: ProductCategory[];
    attributeValues: any[];
    partnerSku: string | null;
    customName: string;
    available: boolean;
    isBlockedOnSpotMarket: boolean;
    tags: any[];
    availableOnMarket: boolean;
    productDefinitionId: string;
}

export interface CreateProductRequest {
    available: boolean;
    price: string;
    masterProductId: string;
    minimumGuaranteedAmount: number;
    grainSize?: {
        min: number;
        max: number;
        unit: string;
    };
    isBlockedOnSpotMarket: boolean;
    attributeValues: string[];
}

export interface PatchProductRequest {
    available: boolean;
    isBlockedOnSpotMarket: boolean;
    minimumGuaranteedAmount: number;
    price: number;
}

export interface SupplierProductPreset {
    id: string;
    name: string;
    category: string;
    masterProductId: string;
    attributeValues: string[];
}

export const useGetSellerProductsByFactory = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getSellerProductsByFactory: (factoryId: number) =>
            monolithClient
                .get<{ items: SellerProduct[] }>(`/v1/factories/${factoryId}/seller-products`)
                .then(res => res.data),
    };
};

export const useCreateSellerProduct = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        createSellerProduct: ({ factoryId, body }: { factoryId: number; body: CreateProductRequest }) =>
            monolithClient.post(`/v2/factories/${factoryId}/seller-products`, body).then(res => res.data),
    };
};

export const usePatchProduct = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        patchProduct: ({ productId, body }: { productId: string; body: PatchProductRequest }) =>
            monolithClient.patch(`/v1/seller-products/${productId}`, body).then(res => res.data),
    };
};

export const useDeleteProduct = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        deleteProduct: ({ productId }: { productId: string }) =>
            monolithClient.delete(`/v1/seller-products/${productId}`),
    };
};

export const useGetSellerProductPresets = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getSellerProductPresets: () =>
            monolithClient
                .get<{ presets: SupplierProductPreset[] }>(`/v1/seller-product-presets`)
                .then(res => res.data),
    };
};
