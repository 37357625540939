import { GetQuoteListByOrderIdResponsePayload, ListResponseBody } from '@schuettflix/interfaces';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetQuotesByOrder = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        getQuotesByOrder: async (orderId: string) => {
            return customRequestClient
                .get<
                    ListResponseBody<z.input<typeof GetQuoteListByOrderIdResponsePayload>>
                >(`/v1/quote/order/${orderId}`)
                .then(response =>
                    response.data.items.map(item => {
                        return GetQuoteListByOrderIdResponsePayload.parse(item).payload;
                    })
                );
        },
    };
};
