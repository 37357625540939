import { useQuery } from '@tanstack/react-query';
import { useGetSupportedUnitsByProductType } from '@/clients/orderProducts/orderProductsClient.ts';
import { GetSupportedUnitListResponseSchema, ListResponseBody, PRODUCT_TYPES } from '@schuettflix/interfaces';
import { z } from 'zod';

export function select(data: ListResponseBody<z.infer<typeof GetSupportedUnitListResponseSchema>>) {
    return data.items.map(item => item.unitName);
}

export function useSupportedUnitNamesByProductType(productType: PRODUCT_TYPES) {
    const { getSupportedUnitsByProductType } = useGetSupportedUnitsByProductType();

    return useQuery({
        queryKey: ['supported-unit-by-product-type', productType],
        queryFn: () => getSupportedUnitsByProductType(productType),
        select: response => select(response.data),
    });
}
