import { useEffect } from 'react';
import { z } from 'zod';
import { PostOrderResponseSchema } from '@schuettflix/interfaces';
import { useNavigate, useSearchParams } from 'react-router';
import { FullscreenLoadingSpinner } from '@/shared/components/FullscreenLoadingSpinner.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { MarketCodeSchema } from '@/shared/types/MarketCode';
import { useMarketContext } from '@/shared/context/MarketContext.tsx';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export function CreateDraftOrderPage() {
    const [searchParams] = useSearchParams();
    const searchMarketCode = MarketCodeSchema.safeParse(searchParams.get('marketCode'));
    const { marketCode } = useMarketContext();
    const { getMarketScopedOrderClient } = useHttpClientContext();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    useEffect(
        () => {
            queryClient.removeQueries();
            getMarketScopedOrderClient(searchMarketCode.data || marketCode)
                .post<z.input<typeof PostOrderResponseSchema>>('/v1/order')
                .then(res => PostOrderResponseSchema.parse(res.data))
                .then(data => navigate(`/order/${data.id}`))
                .catch(e => {
                    throw new Error('Draft order could not be created', { cause: e });
                });
        },
        // This is expected behavior because the effect hook should only run once to create a draft order.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <FullscreenLoadingSpinner />;
}
