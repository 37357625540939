import {
    DeleteOrderProductUrlSchema,
    GetProductGroupListResponseSchema,
    GetProductGroupResponse,
    GetProductGroupResponseSchema,
    GetSupportedUnitListResponseSchema,
    ListResponseBody,
    PatchOrderProductConfigurationRequest,
    PatchOrderProductConfigurationResponse,
    PatchOrderProductConfigurationResponseSchema,
    PatchOrderProductConfigurationUrl,
    PostOrderProductResponseSchema,
    PostProductGroupResponseSchema,
    PRODUCT_CATEGORY,
    PRODUCT_GROUP_TYPE,
    PRODUCT_TYPES,
} from '@schuettflix/interfaces';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export function useCreateProductSelectionApi() {
    const { orderClient } = useHttpClientContext();

    return {
        createProductSelection: (
            type: PRODUCT_TYPES,
            category: PRODUCT_CATEGORY,
            productGroupId: string,
            configurationId?: string,
            index?: number,
            templateId?: string
        ) =>
            orderClient
                .post<z.input<typeof PostOrderProductResponseSchema>>(
                    `/v1/product-group/${productGroupId}/order-product`,
                    {
                        type,
                        configurationId,
                        index,
                        templateId,
                        category,
                    }
                )
                .then(res => PostOrderProductResponseSchema.parse(res.data)),
    };
}

export function useCreateProductGroupApi() {
    const { orderClient } = useHttpClientContext();
    return {
        createProductGroup: ({
            orderId,
            productGroupName,
            index,
            templateId,
            type,
        }: {
            orderId: string;
            productGroupName?: string;
            index: number;
            templateId: string;
            type: PRODUCT_GROUP_TYPE;
        }) =>
            orderClient
                .post<z.input<typeof PostProductGroupResponseSchema>>('/v1/product-group', {
                    orderId,
                    name: productGroupName,
                    index,
                    templateId,
                    type,
                })
                .then(res => PostProductGroupResponseSchema.parse(res.data)),
    };
}

export const useDeleteProductSelectionApi = () => {
    const { orderClient } = useHttpClientContext();

    return {
        deleteProductSelection: async (orderProductId: string) => {
            await orderClient.delete<z.input<typeof DeleteOrderProductUrlSchema>>(
                `/v1/order-product/${orderProductId}`
            );
        },
    };
};

export const useGetProductGroupByIdApi = () => {
    const { orderClient } = useHttpClientContext();

    return {
        getProductGroupById: async (productGroupId: string) => {
            return orderClient
                .get<GetProductGroupResponse>(`/v1/product-group/${productGroupId}`)
                .then(res => GetProductGroupResponseSchema.parse(res.data));
        },
    };
};

export const useGetOrderProductGroupsApi = () => {
    const { orderClient } = useHttpClientContext();

    return {
        getOrderProductGroups: async (orderId: string) => {
            return orderClient
                .get<ListResponseBody<z.input<typeof GetProductGroupListResponseSchema>>>('/v1/product-groups', {
                    params: {
                        orderId,
                    },
                })
                .then(res => res.data.items.map(item => GetProductGroupListResponseSchema.parse(item)));
        },
    };
};

export const useGetSupportedUnitsByProductType = () => {
    const { orderClient } = useHttpClientContext();

    return {
        getSupportedUnitsByProductType: async (productType: string) => {
            return orderClient.get<ListResponseBody<z.infer<typeof GetSupportedUnitListResponseSchema>>>(
                `/v1/product-type/${productType}/supported-units`
            );
        },
    };
};

export const usePatchOrderProductConfiguration = () => {
    const { orderClient } = useHttpClientContext();

    return {
        patchOrderProductConfiguration: async ({
            orderProductId,
            orderProductConfiguration,
        }: {
            orderProductId: PatchOrderProductConfigurationUrl['orderProductId'];
            orderProductConfiguration: PatchOrderProductConfigurationRequest;
        }) => {
            return orderClient
                .patch<PatchOrderProductConfigurationResponse>(
                    `/v1/product-configuration/${orderProductId}`,
                    orderProductConfiguration
                )
                .then(response => PatchOrderProductConfigurationResponseSchema.parse(response.data));
        },
    };
};
