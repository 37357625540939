import { TaxClass } from '@/clients/tax-classes/types/taxClasses';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetTaxClasses = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getTaxClasses: (marketCode: string) =>
            monolithClient.get<TaxClass[]>(`/v1/tax-class/${marketCode}/list`).then(res => res.data),
    };
};
