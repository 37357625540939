import { PRODUCT_CATEGORY } from '@schuettflix/interfaces';
import type { ProductCategoryIcon } from '@/shared/types/prefill-template-data.ts';

export function getProductCategoryIconName(productCategory: PRODUCT_CATEGORY): ProductCategoryIcon {
    switch (productCategory) {
        case 'TRANSPORT':
            return 'transport';
        case 'MATERIAL':
            return 'material';
        case 'MATERIAL_SERVICE':
        case 'TRANSPORT_SERVICE':
            return 'service';
    }
}
