import { useQuery } from '@tanstack/react-query';
import { useGetAvailableProductDefinitionsByFactory, useGetFactoriesByOrganizationId } from './Factory.ts';

export function useOrganizationFactories(organizationId: number | null) {
    const { getFactoriesByOrganizationId } = useGetFactoriesByOrganizationId();
    return useQuery({
        queryKey: ['organization', 'factories', organizationId],
        queryFn: () => (organizationId !== null ? getFactoriesByOrganizationId(organizationId) : null),
        enabled: !!organizationId,
        refetchOnWindowFocus: false,
    });
}

export function useAvailableFactoryProducts(factoryId: number | null) {
    const { getAvailableProductDefinitionsByFactory } = useGetAvailableProductDefinitionsByFactory();
    return useQuery({
        queryKey: ['factory', factoryId, 'available-products'],
        queryFn: () => (factoryId !== null ? getAvailableProductDefinitionsByFactory(factoryId) : null),
        enabled: !!factoryId,
        refetchOnWindowFocus: false,
    });
}
