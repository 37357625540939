import type { PlatformInfo } from './types/platformTypes';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetPlatformInfo = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getPlatformInfo: async () => {
            return monolithClient.get<PlatformInfo>('/platforminfo').then(res => res.data);
        },
    };
};

export * from './types/platformTypes';
