import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { z } from 'zod';
import { LineItemListingSchema, PRODUCT_CATEGORY, SUPPORTED_UNITS } from '@schuettflix/interfaces';
import { ProductFallbackDataMap } from '../types/ProductFallbackDataMap';
import { useFulfillmentContext } from './FulfillmentContext';

type LineItemListing = z.infer<typeof LineItemListingSchema>;

export type DuplicateCheckContextType = {
    productDataMap: Record<
        string,
        {
            partnerId?: number;
            unit: SUPPORTED_UNITS | undefined;
            productCategory: PRODUCT_CATEGORY;
        }
    >;
    items: LineItemListing[];
};

const DuplicateCheckContext = createContext<DuplicateCheckContextType | undefined>(undefined);

export const DuplicateCheckContextProvider = ({
    productFallbackDataMap,
    children,
}: PropsWithChildren<{ productFallbackDataMap: ProductFallbackDataMap }>) => {
    const { products, lineItemListing } = useFulfillmentContext();
    const duplicateCheckContext = useMemo<DuplicateCheckContextType>(() => {
        const items = lineItemListing || [];
        const productIds = products.map(product => product.id);
        const productDataMap = Object.fromEntries(
            productIds.map(productId => {
                const productCategory = products.find(p => p.id === productId)?.category;
                if (!productCategory) throw new Error(`Product with id ${productId} not found`);
                return [
                    productId,
                    {
                        partnerId:
                            productFallbackDataMap[productId]?.partnerId ||
                            products.find(p => p.id === productId)?.quote?.partnerOrganizationId ||
                            undefined,
                        unit:
                            productFallbackDataMap[productId]?.unit ||
                            products.find(p => p.id === productId)?.quote?.unit ||
                            undefined,
                        productCategory,
                    },
                ];
            })
        );
        return {
            items,
            productDataMap,
        };
    }, [productFallbackDataMap, products, lineItemListing]);
    return <DuplicateCheckContext.Provider value={duplicateCheckContext}>{children}</DuplicateCheckContext.Provider>;
};

export const useDuplicateCheckContext = () => {
    const context = useContext(DuplicateCheckContext);
    if (context === undefined) {
        throw new Error('useDuplicateCheckContext must be used within a DuplicateCheckContextProvider');
    }
    return context;
};

//////////////////////////////////////////
// Some utilities                       //
//////////////////////////////////////////

export const getPartnerId = (
    productId: string,
    { productDataMap }: Pick<DuplicateCheckContextType, 'productDataMap'>
) => {
    const productData = productDataMap[productId];
    return productData?.partnerId;
};

export const getUnit = (productId: string, { productDataMap }: Pick<DuplicateCheckContextType, 'productDataMap'>) => {
    const productData = productDataMap[productId];
    return productData?.unit;
};

export const getProductCategory = (
    productId: string,
    { productDataMap }: Pick<DuplicateCheckContextType, 'productDataMap'>
) => {
    const productData = productDataMap[productId];
    if (!productData) throw new Error(`No product data found for product with id ${productId}`);
    return productData.productCategory;
};
