import { useZodForm } from '@/shared/hooks/useZodForm';
import { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { IndividualLineItemForm } from './IndividualLineItemForm';
import { IndividualLineItemSchema } from '../shared/IndividualLineItemSchema';
import { useFulfillmentContext } from '../context/FulfillmentContext';
import { LineItemListingSchema } from '@schuettflix/interfaces';
import { ProductFallbackDataMap } from '../types/ProductFallbackDataMap';
import { DndContext, closestCenter, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { IndividualLineItemListingFormSchema } from '../shared/IndividualLineItemListingFormSchema';
import { DuplicateCheckContextProvider } from '../context/DuplicateCheckContext';
import { useGetRelatedTransportLineItem } from '../hooks/useGetRelatedTransportLineItem';

type LineItemListing = z.infer<typeof LineItemListingSchema>;

type ProductSelectionIndividualLineItemListingProps = {
    onIsDirtyChange: (isDirty: boolean) => void;
    productFallbackDataMap: ProductFallbackDataMap;
};

export const ProductSelectionIndividualLineItemListing = ({
    onIsDirtyChange: setIsDirty,
    productFallbackDataMap,
}: ProductSelectionIndividualLineItemListingProps) => {
    const { t } = useTranslation();

    const { control, register, formState } = useZodForm({
        schema: IndividualLineItemListingFormSchema,
        defaultValues: {
            items: [{ referenceDocument: '', licensePlate: '', serviceDate: '' }],
        },
        mode: 'onChange',
    });

    const { fields, append, remove, insert, move } = useFieldArray({
        control,
        name: 'items',
    });

    const sensors = useSensors(useSensor(PointerSensor));
    const values = useWatch({ control });

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over || active.id === over.id) {
            return;
        }

        const oldIndex = fields.findIndex(field => field.id === active.id);
        const newIndex = fields.findIndex(field => field.id === over.id);

        // Prevent moving an item below the last movable item
        const lastMovableIndex = fields.length - 2; // Second-to-last row is the last movable
        if (newIndex > lastMovableIndex) {
            return;
        }

        move(oldIndex, newIndex);
    };

    useEffect(() => {
        // for some reason formState.isDirty doesn't work as expected, so we have to calculate it manually
        const isDirty = JSON.stringify(values) !== JSON.stringify(formState.defaultValues);
        setIsDirty(isDirty);
    }, [values, formState, setIsDirty]);

    // set the values (which represents individual line items) to the FulfillmentContext
    const { setLineItemListing } = useFulfillmentContext();
    const { getRelatedTransportLineItem } = useGetRelatedTransportLineItem();

    useEffect(() => {
        const validItemsWithoutSortIndex = (values.items || []).flatMap((item, index) => {
            const { success, data } = IndividualLineItemSchema.safeParse(item);
            if (!success) return [];

            const groupKey = index;
            const withGroupKey = <TLineItem extends object>(lineItem: TLineItem) => ({ ...lineItem, groupKey });
            const transportLineItem = getRelatedTransportLineItem(data);
            return (
                transportLineItem ? [withGroupKey(data), withGroupKey(transportLineItem)] : [withGroupKey(data)]
            ) satisfies Omit<LineItemListing, 'sortIndex' | 'licensePlate'>[];
        });
        const validItems = validItemsWithoutSortIndex.map((item, index) =>
            LineItemListingSchema.parse({ ...item, sortIndex: index })
        );
        setLineItemListing(validItems);
        return () => {
            setLineItemListing(null);
        };
    }, [setLineItemListing, values, getRelatedTransportLineItem]);

    // add a new empty LineItemForm whenever the former last form was filled with some data and autoselect the next field
    useEffect(() => {
        if (values.items?.slice(-1)[0].productId) {
            append(
                {},
                {
                    focusName: `items.${values.items.length - 1}.amount`,
                }
            );
        }
    }, [values, append]);

    return (
        <div className="mt-8 rounded bg-white p-4">
            <p className="font-copy-sm-strong uppercase">
                {t('sections.productGroupSelection.individualLineItemListing.sectionTitle')}
            </p>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <SortableContext items={fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
                    <DuplicateCheckContextProvider productFallbackDataMap={productFallbackDataMap}>
                        <form className="mt-4">
                            <div className="flex flex-col gap-4">
                                {fields.map((field, index) => (
                                    <IndividualLineItemForm
                                        key={field.id}
                                        id={field.id}
                                        control={control}
                                        register={register}
                                        index={index}
                                        disableActions={index === fields.length - 1}
                                        productFallbackDataMap={productFallbackDataMap}
                                        /*
                                            Forces validation when the form is the only item in the list,
                                            ensuring errors are shown even if empty.
                                        */
                                        forceValidation={fields.length === 1}
                                        onRemove={() => remove(index)}
                                        onCopy={value => {
                                            insert(index + 1, value, {
                                                shouldFocus: false,
                                            });
                                        }}
                                    />
                                ))}
                            </div>
                        </form>
                    </DuplicateCheckContextProvider>
                </SortableContext>
            </DndContext>
        </div>
    );
};
