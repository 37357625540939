import { LaptopIcon, MaterialIcon, VehicleIcon } from '@schuettflix/icons-react';
import { PRODUCT_CATEGORY } from '@schuettflix/interfaces';

export function getProductCategoryIcon(productCategory: PRODUCT_CATEGORY) {
    switch (productCategory) {
        case 'MATERIAL':
            return MaterialIcon;
        case 'TRANSPORT':
            return VehicleIcon;
        case 'MATERIAL_SERVICE':
        case 'TRANSPORT_SERVICE':
            return LaptopIcon;
    }
}
