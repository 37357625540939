import { useIdTokenContext } from '@/shared/context/IdTokenContext.tsx';
import { useMemo } from 'react';
import { MarketCode } from '@/shared/types/MarketCode.ts';
import { Select } from '@schuettflix/react-components';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useMarketContext } from '@/shared/context/MarketContext.tsx';

// The purpose of this component is to provide a route outlet with a market that can be manually selected above it.
// It is scoped in both layout and translations to the Supply Management usecase, hence it is placed in that module.
// Should this need arise in other modules, this component can be parameterized to be more generic and moved elsewhere.
export const SelectedMarketRouteWrapper = () => {
    const { getAvailableMarketCodes } = useIdTokenContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { marketCode, setMarketCode } = useMarketContext();

    const options = useMemo(() => {
        const availableMarketCodes = getAvailableMarketCodes();
        return availableMarketCodes.map(marketCode => ({
            value: marketCode,
            label: marketCode,
        }));
    }, [getAvailableMarketCodes]);

    const handleMarketCodeChange = (val: MarketCode | null) => {
        if (!val) {
            return;
        }
        queryClient.removeQueries();
        setMarketCode(val);
    };

    return (
        <div className="flex min-h-screen flex-col">
            {options.length > 1 ? (
                <section className="lg:px-18 pt-4px px-2 py-6 xl:px-[122px]">
                    <h3 className="font-copy-md-strong mb-2">
                        {t('supply-management.products.marketSelectionSection.title')}
                    </h3>
                    <Select<MarketCode>
                        value={marketCode}
                        onChange={handleMarketCodeChange}
                        options={options}
                        label={t('sections.platformSelection.platformLabel')}
                    />
                </section>
            ) : null}
            <Outlet key={marketCode} />
        </div>
    );
};
