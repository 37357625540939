import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetPositionSummaryById } from './position';

export function getPositionSummaryQueryKey(positionId: string) {
    return ['position-summary', positionId];
}

export const usePositionSummary = (positionId: string) => {
    const { getPositionSummaryById } = useGetPositionSummaryById();

    return useSuspenseQuery({
        queryKey: getPositionSummaryQueryKey(positionId),
        queryFn: () => getPositionSummaryById(positionId),
        refetchOnWindowFocus: false,
    });
};
