import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetTemplate } from '@/clients/template/template.ts';
import { PRODUCT_NAME_LABEL, Template } from '@schuettflix/interfaces';
import { Position } from '@/shared/types/prefill-template-data';
import { getProductCategoryIconName } from '@/modules/order/components/ProductSelection/hooks/useAllowedProductsByTemplate/utils/getProductCategoryIconName.ts';

export const useAllowedProductsByTemplate = (templateId: string | null) => {
    const { getTemplate } = useGetTemplate();
    const query = useSuspenseQuery({
        queryKey: ['template', templateId],
        queryFn: async () => (templateId ? getTemplate(templateId) : null),
        refetchOnWindowFocus: false,
        select: data => (data ? getAllowedPositionsPerTemplate(data) : null),
    });

    return query.data ?? [];
};

function getAllowedPositionsPerTemplate(template: Template): Position[] {
    return (
        template?.allowedProducts?.map(product => ({
            productName: PRODUCT_NAME_LABEL[product.type],
            product,
            icon: getProductCategoryIconName(product.category),
        })) ?? []
    );
}
