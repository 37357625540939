import { useCallback } from 'react';
import { useFulfillmentContext } from '../context/FulfillmentContext';

export const useGetRelatedTransportLineItem = () => {
    const { productGroup, products } = useFulfillmentContext();

    /**
     * Gets the according transport product as a lineItem from a given material productId in franco and merchant cases.
     * If we are in a standard case or if the given productId is not a material, returns undefined.
     *
     * @returns A lineItem of the related transport product if applicable.
     */
    const getRelatedTransportLineItem = useCallback(
        <TLineItem extends { productId: string }>(lineItem: TLineItem): TLineItem | undefined => {
            if (productGroup.type === 'STANDARD') return;

            const product = products.find(p => p.id === lineItem.productId);
            if (product?.category !== 'MATERIAL') return;

            const transportProduct = products.find(p => p.category === 'TRANSPORT');
            if (!transportProduct) throw new Error('Expected to find a transport product for FRANCO/MERCHANT');
            return { ...lineItem, productId: transportProduct.id };
        },
        [productGroup.type, products]
    );

    return {
        getRelatedTransportLineItem,
    };
};
