import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import React, { useState } from 'react';
import { Button, Combobox, TextField, ToggleSwitch } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { useCreateSellerProduct } from '@/clients/sellerProducts/SellerProduct.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAvailableFactoryProducts } from '@/clients/factories/useFactories.ts';
import { invalidateSellerProductsQuery } from '@/clients/sellerProducts/useSellerProduct.ts';
import { useTranslation } from 'react-i18next';

const CreateForm = z.object({
    available: z.boolean(),
    productDefinitionId: z.number().nonnegative().nullable(),
    pricePerTon: z.number().positive(),
    stockInTons: z.number().positive(),
    isBlockedOnSpotMarket: z.boolean(),
});

export function CreateFactoryProductForm({ factoryId }: { factoryId: number }) {
    const { data: availableProductDefinitions } = useAvailableFactoryProducts(factoryId);
    const { t } = useTranslation();

    const {
        handleSubmit,
        register,
        control,
        formState: { isValid, isDirty, errors },
        reset,
    } = useZodForm({
        schema: CreateForm,
        defaultValues: {
            available: true,
            productDefinitionId: null,
            pricePerTon: 0,
            stockInTons: 0,
            isBlockedOnSpotMarket: true,
        },
        mode: 'onChange',
    });

    const queryClient = useQueryClient();
    const [formBodyError, setFormBodyError] = useState<string | null>(null);
    const { createSellerProduct } = useCreateSellerProduct();
    const createSellerProductMutation = useMutation({
        mutationKey: ['seller-products', 'post', factoryId],
        mutationFn: createSellerProduct,
        onError: (error: unknown) => {
            const validationError = z
                .object({
                    response: z.object({
                        data: z.array(
                            z.object({
                                property_path: z.string(),
                                message: z.string(),
                            })
                        ),
                    }),
                })
                .safeParse(error);
            if (validationError.success) {
                for (const error of validationError.data.response.data) {
                    switch (error.property_path) {
                        case 'body': {
                            setFormBodyError(error.message);
                            break;
                        }
                    }
                }
            }
        },
        onSuccess: () => {
            setFormBodyError(null);
        },
    });

    return (
        <>
            <form
                onSubmit={handleSubmit(async formData => {
                    const orderProductDefinition = availableProductDefinitions?.items?.find(
                        product => product.id === formData.productDefinitionId
                    );
                    if (!orderProductDefinition) {
                        return;
                    }
                    await createSellerProductMutation.mutateAsync({
                        factoryId,
                        body: {
                            available: formData.available,
                            price: formData.pricePerTon.toString(10),
                            masterProductId: orderProductDefinition.masterProductId,
                            minimumGuaranteedAmount: formData.stockInTons,
                            ...(orderProductDefinition.grainSize
                                ? {
                                      grainSize: {
                                          min: orderProductDefinition.grainSize.min,
                                          max: orderProductDefinition.grainSize.max,
                                          unit: orderProductDefinition.grainSize.unit,
                                      },
                                  }
                                : {}),
                            isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                            attributeValues: orderProductDefinition.attributeValueIds,
                        },
                    });
                    reset();
                    await invalidateSellerProductsQuery(queryClient, factoryId);
                })}
            >
                <div className="flex flex-wrap justify-between md:flex-nowrap">
                    <div className="grow">
                        <div className="flex grow flex-wrap gap-4 pb-6">
                            <Controller
                                name={'productDefinitionId'}
                                control={control}
                                render={({ field }) => (
                                    <Combobox
                                        className="max-w-[350px] basis-[350px]"
                                        value={field.value}
                                        onChange={field.onChange}
                                        searchFn={(options, searchTerm) => {
                                            return options
                                                .filter(option =>
                                                    searchTerm.split(/\s+/).every(word => {
                                                        return option.label.toLowerCase().includes(word.toLowerCase());
                                                    })
                                                )
                                                .sort((a, b) => a.label.length - b.label.length);
                                        }}
                                        options={
                                            availableProductDefinitions?.items?.map(product => ({
                                                value: product.id,
                                                label: product.name,
                                            })) ?? []
                                        }
                                        label={t('supply-management.products.form.productSelection.label')}
                                        multiple={false}
                                    />
                                )}
                            />
                            <TextField
                                className="max-w-[350px] basis-[350px]"
                                {...register('pricePerTon', { valueAsNumber: true })}
                                label={t('supply-management.products.form.pricePerTon.label')}
                                errorMessage={errors.pricePerTon?.message}
                                type="number"
                                step={0.01}
                            />
                            <TextField
                                className="max-w-[350px] basis-[350px]"
                                {...register('stockInTons', { valueAsNumber: true })}
                                label={t('supply-management.products.form.stockInTons.label')}
                                errorMessage={errors.stockInTons?.message}
                                type="number"
                                step={0.01}
                            />
                        </div>
                        <div className="flex flex-wrap gap-4">
                            <Controller
                                control={control}
                                name={'available'}
                                render={({ field }) => (
                                    <ToggleSwitch
                                        label={t('supply-management.products.form.active.label')}
                                        {...field}
                                        checked={field.value}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={'isBlockedOnSpotMarket'}
                                render={({ field }) => (
                                    <ToggleSwitch
                                        label={t('supply-management.products.form.onlyVisibleForSflx.label')}
                                        {...field}
                                        checked={field.value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Button
                        label={t('supply-management.products.form.submitButton.label')}
                        type="submit"
                        disabled={!isValid || !isDirty}
                    />
                </div>
            </form>
            {formBodyError ? <p className="text-critical">{formBodyError}</p> : null}
        </>
    );
}
