import React from 'react';
import { Toaster } from '@/shared/components/Toaster.tsx';
import { Route, Routes } from 'react-router';
import { AuthGuard } from '@/routes/AuthGuard.tsx';
import { CreateDraftOrderPage } from '@/modules/CreateDraftOrderPage.tsx';
import { RouteWithOrderId } from '@/routes/RouteWithOrderId.tsx';
import { OrderPage } from '@/modules/order/OrderPage.tsx';
import { RouteWithPositionId } from '@/routes/RouteWithPositionId.tsx';
import { PositionEditPage } from '@/modules/position/PositionEditPage.tsx';
import { PositionPage } from '@/modules/position/PositionPage.tsx';
import { FulfillmentPage } from '@/modules/fulfillment/FulfillmentPage.tsx';
import { LoginCallback } from '@okta/okta-react';
import { FullscreenLoadingSpinner } from '@/shared/components/FullscreenLoadingSpinner.tsx';
import { SelectedMarketRouteWrapper } from '@/modules/supplierProductManagement/SelectedMarketRouteWrapper.tsx';
import { SupplierManagementPage } from '@/modules/supplierProductManagement/SupplierManagementPage.tsx';

// NOTE: The layout routes below ensure that the correct context is defined for the route.
// See: https://reactrouter.com/en/main/route/route#layout-routes
export function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<AuthGuard />}>
                    <Route path="" element={<CreateDraftOrderPage />} />
                    <Route element={<RouteWithOrderId />}>
                        <Route path="order/:orderId" element={<OrderPage />} />
                    </Route>
                    <Route element={<RouteWithPositionId />}>
                        <Route path="position/:positionId/edit/:temporaryId?" element={<PositionEditPage />} />
                        <Route path="position/:positionId" element={<PositionPage />} />
                    </Route>
                    <Route element={<RouteWithOrderId />}>
                        <Route path="fulfillment/:orderId" element={<FulfillmentPage />} />
                    </Route>
                    <Route element={<SelectedMarketRouteWrapper />}>
                        <Route path="supply-management/products" element={<SupplierManagementPage />} />
                    </Route>
                </Route>
                <Route
                    path="/login/callback"
                    element={<LoginCallback loadingElement={<FullscreenLoadingSpinner />} />}
                />
            </Routes>
            <Toaster />
        </>
    );
}
