import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { MarketCode } from '@/shared/types/MarketCode.ts';
import { getMarketsFromToken } from '@/shared/utils/getMarketsFromToken.ts';
import { decodeToken, JWTPayload } from '@okta/okta-auth-js';

export type IdTokenContextType = {
    initialize: (idToken: string) => void;
    isInitialized: () => boolean;
    isMarketAvailable: (marketCode: MarketCode) => boolean;
    getIdToken: () => string;
    getIdTokenPayload: () => JWTPayload;
    getDefaultMarketCode: () => MarketCode;
    getAvailableMarketCodes: () => MarketCode[];
};

export const IdTokenContext = createContext<IdTokenContextType | undefined>(undefined);

export function IdTokenContextProvider({ children }: PropsWithChildren) {
    const [idToken, setIdToken] = useState<string | undefined>(undefined);
    const [availableMarketCodes, setAvailableMarketCodes] = useState<MarketCode[] | undefined>(undefined);

    const initialize = useCallback((idToken: string) => {
        const availableMarketCodes = getMarketsFromToken(idToken);

        if (availableMarketCodes.length === 0) {
            throw new Error(`Authenticated user has no access to any market`);
        }

        setIdToken(idToken);
        setAvailableMarketCodes(availableMarketCodes);
    }, []);

    const isInitialized = useCallback(
        () => !(idToken === undefined || availableMarketCodes === undefined),
        [idToken, availableMarketCodes]
    );

    const getIdToken = useCallback(() => {
        if (idToken === undefined) {
            throw new Error(`Context needs to be initialized via \`initialize(idToken)\``);
        }
        return idToken;
    }, [idToken]);

    const getDefaultMarketCode = useCallback(() => {
        if (availableMarketCodes === undefined) {
            throw new Error(`Context needs to be initialized via \`initialize(idToken)\``);
        }
        return availableMarketCodes[0];
    }, [availableMarketCodes]);

    const getAvailableMarketCodes = useCallback(() => {
        if (availableMarketCodes === undefined) {
            throw new Error(`Context needs to be initialized via \`initialize(idToken)\``);
        }
        return availableMarketCodes;
    }, [availableMarketCodes]);

    const getIdTokenPayload = useCallback(() => decodeToken(getIdToken()).payload, [getIdToken]);

    const isMarketAvailable = useCallback(
        (marketCode: MarketCode) => getAvailableMarketCodes().includes(marketCode),
        [getAvailableMarketCodes]
    );

    return (
        <IdTokenContext.Provider
            value={{
                initialize,
                isInitialized,
                isMarketAvailable,
                getIdToken,
                getIdTokenPayload,
                getDefaultMarketCode,
                getAvailableMarketCodes,
            }}
        >
            {children}
        </IdTokenContext.Provider>
    );
}

export function useIdTokenContext() {
    const context = useContext(IdTokenContext);
    if (!context) {
        throw new Error('IdTokenContext must be provided!');
    }
    return context;
}
