import { MaybePromise } from '@/shared/types/MaybePromise';
import { IndividualLineItemInput, PartialIndividualLineItemInput } from '../shared/IndividualLineItemSchema';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { RequiredAndTruthy } from '@/shared/types/RequiredAndTruthy';
import { useLatest } from 'react-use';
import {
    DuplicateCheckContextType,
    getPartnerId,
    getProductCategory,
    getUnit,
    useDuplicateCheckContext,
} from '../context/DuplicateCheckContext';
import { PRODUCT_CATEGORY, SUPPORTED_UNITS } from '@schuettflix/interfaces';
import { useGetRelatedTransportLineItem } from './useGetRelatedTransportLineItem';
import { useDuplicateCheckCurrentForm } from './useDuplicateCheckCurrentForm';
import { useFulfillmentContext } from '../context/FulfillmentContext';
import { useDuplicateCheckCustomRequest } from './useDuplicateCheckCustomRequest';

type LineItemWithDuplicateRelevantData = RequiredAndTruthy<
    Pick<IndividualLineItemInput, 'amount' | 'productId' | 'referenceDocument' | 'serviceDate'>
>;
type AdditionalDuplicateData = {
    index: number;
    partnerId: number | undefined;
    unit: SUPPORTED_UNITS | undefined;
    productCategory: PRODUCT_CATEGORY;
};
type DuplicateCheckInput = LineItemWithDuplicateRelevantData & AdditionalDuplicateData;
type DuplicateCheckResult = { message: ReactNode } | undefined;
type DuplicateCheckFunction = (
    args: DuplicateCheckInput,
    context: DuplicateCheckContextType
) => MaybePromise<DuplicateCheckResult>;
export type DuplicateCheckHook = () => DuplicateCheckFunction;

/**
 * An array of hooks that provide a duplicate check. This can be expanded whenever we have another
 * duplicate check that needs to be run.
 *
 * The checks are run in sequence until a check returns truthy.
 */
const duplicateCheckHooks: DuplicateCheckHook[] = [useDuplicateCheckCurrentForm, useDuplicateCheckCustomRequest];

/**
 * Custom hook to perform duplicate checks on an individual line item.
 *
 * This hook will run all available duplicate checks on the given line item
 * and it's possibly related transport line item (franco/merchant).
 *
 * Use the `runDuplicateCheck` function to trigger the checks.
 */
export const useDuplicateCheck = ({
    individualLineItem,
    index,
}: {
    individualLineItem: PartialIndividualLineItemInput;
    index: number;
}) => {
    const duplicateCheckContext = useDuplicateCheckContext();
    const { getRelatedTransportLineItem } = useGetRelatedTransportLineItem();
    const { setCanSubmit } = useFulfillmentContext();
    const [duplicateCheckState, setDuplicateCheckState] = useState<DuplicateCheckResult>(undefined);
    const duplicateChecks: DuplicateCheckFunction[] = duplicateCheckHooks.map(hook => hook());

    const individualLineItemLatest = useLatest(individualLineItem);
    const duplicateCheckContextLatest = useLatest(duplicateCheckContext);

    const runDuplicateCheck = useCallback(() => {
        const lineItem = individualLineItemLatest.current;
        if (!hasLineItemAllNeededData(lineItem)) return setDuplicateCheckState(undefined);

        // in case of franco or merchant, we need to check the transport line item as well
        const transportLineItem = getRelatedTransportLineItem(lineItem);
        const lineItemsToCheck = transportLineItem ? [lineItem, transportLineItem] : [lineItem];

        const check = async () => {
            for (const duplicateCheck of duplicateChecks) {
                for (const lineItem of lineItemsToCheck) {
                    const partnerId = getPartnerId(lineItem.productId, duplicateCheckContextLatest.current);
                    const unit = getUnit(lineItem.productId, duplicateCheckContextLatest.current);
                    const productCategory = getProductCategory(lineItem.productId, duplicateCheckContextLatest.current);
                    const result = await duplicateCheck(
                        { ...lineItem, index, partnerId, unit, productCategory },
                        duplicateCheckContextLatest.current
                    );
                    if (result) return result;
                }
            }
        };

        setCanSubmit(false);
        void check()
            .then(setDuplicateCheckState)
            .finally(() => setCanSubmit(true));

        // we expand the duplicateChecks array here to make sure that the runDuplicateCheck is recreated
        // when the duplicateCheck functions change, without needing to make
        // sure that the duplicateChecks array itself is referentially stable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...duplicateChecks, index]);

    // we only want to run it once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(runDuplicateCheck, []);

    return {
        runDuplicateCheck,
        duplicateCheckState,
    };
};

export const hasLineItemAllNeededData = (
    lineItem: PartialIndividualLineItemInput
): lineItem is LineItemWithDuplicateRelevantData => {
    const { productId, amount, referenceDocument, serviceDate } = lineItem;
    return !!(productId && amount && referenceDocument && serviceDate);
};
