import { useAllowedProductsByTemplate } from '@/modules/order/components/ProductSelection/hooks/useAllowedProductsByTemplate/useAllowedProductsByTemplate.ts';
import { useTranslation } from 'react-i18next';
import { Position, Product } from '@/shared/types/prefill-template-data.ts';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { Menu } from '@headlessui/react';
import { PlusIcon } from '@schuettflix/icons-react';
import CategoryIcon from '@/shared/components/AddProductButton/components/CategoryIcon.tsx';
import { PRODUCT_NAME_LABEL } from '@schuettflix/interfaces';

interface AddProductButtonProps {
    templateId: string | null;
    onAddProduct: (product: Product) => void;
}

export function AddProductButton({ templateId, onAddProduct }: AddProductButtonProps) {
    const { t } = useTranslation();
    const allowedProducts = useAllowedProductsByTemplate(templateId);

    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-start',
        strategy: 'fixed',
        middleware: [flip(), offset(8)],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Menu as="span">
            <Menu.Button className="flex shrink cursor-pointer items-center gap-2 p-4" ref={refs.setReference}>
                <PlusIcon size="xs" />
                <p className="font-copy-md-strong">{t('product.addPosition.btn')}</p>
            </Menu.Button>
            <Menu.Items
                as="div"
                style={floatingStyles}
                ref={refs.setFloating}
                className="card shadow-high z-10 w-[432px] rounded p-0"
            >
                <Menu.Item as="div" className="font-copy-md-strong p-4">
                    {t('product.addPosition.label')}
                </Menu.Item>
                {allowedProducts.map((position: Position) => (
                    <Menu.Item
                        key={position.product.type}
                        as="div"
                        onClick={() => onAddProduct(position.product)}
                        className="hover:bg-surface-disabled flex w-full cursor-pointer justify-between gap-2 p-4"
                    >
                        <span className="font-copy-md">
                            {t(position.productName as (typeof PRODUCT_NAME_LABEL)[keyof typeof PRODUCT_NAME_LABEL])}
                        </span>
                        <div className="shrink-0">
                            <CategoryIcon productCategory={position.icon} />
                        </div>
                    </Menu.Item>
                ))}
            </Menu.Items>
        </Menu>
    );
}
