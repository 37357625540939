import { Outlet, useParams } from 'react-router';
import { MarketContextProvider } from '@/shared/context/MarketContext.tsx';
import { useOrder } from '@/clients/order/useOrder.ts';
import { MarketCodeSchema } from '@/shared/types/MarketCode.ts';
import { HttpClientContextProvider } from '@/shared/context/HttpClientContext.tsx';

type OrderRouteParams = { orderId: string };

function InnerOrderRoute({ orderId }: OrderRouteParams) {
    const { data } = useOrder(orderId);

    if (!data) {
        return;
    }

    const safeMarketCode = MarketCodeSchema.safeParse(data.marketCode);
    if (!safeMarketCode.success) {
        throw new Error(`Invalid market code: ${data.marketCode}`);
    }

    return (
        <MarketContextProvider marketCode={safeMarketCode.data}>
            <HttpClientContextProvider marketCode={safeMarketCode.data}>
                <Outlet />
            </HttpClientContextProvider>
        </MarketContextProvider>
    );
}

export function RouteWithOrderId() {
    const { orderId } = useParams<OrderRouteParams>();

    if (!orderId) {
        return;
    }

    return <InnerOrderRoute orderId={orderId} />;
}
