import { GetTemplateGroupResponseSchema, GetTemplateResponseSchema, ListResponseBody } from '@schuettflix/interfaces';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetTemplateGroups = () => {
    const { orderClient } = useHttpClientContext();
    return {
        getTemplateGroups: async () => {
            return orderClient
                .get<ListResponseBody<z.input<typeof GetTemplateGroupResponseSchema>>>(`/v1/template-groups`)
                .then(response =>
                    response.data.items.map(templateGroup => GetTemplateGroupResponseSchema.parse(templateGroup))
                );
        },
    };
};

export const useGetTemplate = () => {
    const { orderClient } = useHttpClientContext();
    return {
        getTemplate: async (templateId: string) => {
            return orderClient
                .get<ListResponseBody<z.input<typeof GetTemplateResponseSchema>>>(`/v1/templates/${templateId}`)
                .then(response => GetTemplateResponseSchema.parse(response.data));
        },
    };
};
