import {
    GetQuoteResponseSchema,
    PatchQuoteFulfillmentRequestSchema,
    PatchQuoteRequestSchema,
    PatchQuoteResponseSchema,
    PostQuoteFulfillmentRequestSchema,
    PostQuoteRequestSchema,
    PostQuoteResponseSchema,
    PostReferenceDocumentDuplicateCheckResponseSchema,
    PostReferenceDocumentDuplicateRequestSchema,
} from '@schuettflix/interfaces';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetProductQuoteByProductId = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        getProductQuoteByProductId: ({ productId }: { productId: string }) =>
            customRequestClient
                .get(`/v1/products/${productId}/quote`)
                .then(res => GetQuoteResponseSchema.parse(res.data)),
    };
};

export const usePostProductQuote = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        postProductQuote: (payload: z.input<typeof PostQuoteRequestSchema>) =>
            customRequestClient.post('/v1/quote', payload).then(res => PostQuoteResponseSchema.parse(res.data)),
    };
};

export const usePatchProductQuote = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        patchProductQuote: ({ body, quoteId }: { body: z.input<typeof PatchQuoteRequestSchema>; quoteId: string }) =>
            customRequestClient
                .patch(`/v1/quote/${quoteId}`, body)
                .then(res => PatchQuoteResponseSchema.parse(res.data)),
    };
};

export const usePostFulfillmentQuote = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        postFulfillmentQuote: (payload: z.input<typeof PostQuoteFulfillmentRequestSchema>) =>
            customRequestClient
                .post('/v1/order-fulfill/product', payload)
                .then(res => PostQuoteResponseSchema.parse(res.data)),
    };
};

export const usePatchFulfillmentQuote = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        patchFulfillmentQuote: ({
            body,
            productId,
        }: {
            body: z.input<typeof PatchQuoteFulfillmentRequestSchema>;
            productId: string;
        }) =>
            customRequestClient
                .patch(`/v1/order-fulfill/product/${productId}`, body)
                .then(res => PatchQuoteResponseSchema.parse(res.data)),
    };
};

export const useDeleteFulfillmentQuote = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        deleteFulfillmentQuote: ({ quoteId }: { quoteId: string }) =>
            customRequestClient.delete(`/v1/order-fulfill/quote/${quoteId}`),
    };
};

export const useGetReferenceDocumentDuplicate = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        getReferenceDocumentDuplicate: (params: z.input<typeof PostReferenceDocumentDuplicateRequestSchema>) =>
            customRequestClient
                .post(`/v1/reference-document/duplicate-check`, params)
                .then(({ data }) => PostReferenceDocumentDuplicateCheckResponseSchema.parse(data)),
    };
};
