import {
    GetFulfillmentSummaryRequestSchema,
    GetFulfillmentSummaryResponseSchema,
    GetOrderResponseSchema,
    GetOrderSummaryResponseSchema,
    PatchCustomerInfoRequestSchema,
    PatchCustomerInfoResponseSchema,
    PatchPaymentTermRequestSchema,
    PatchPaymentTermResponseSchema,
    PostCustomerInfoRequestSchema,
    PostCustomerInfoResponseSchema,
    PostOrderFulfillRequestSchema,
    SubmitOrderResponseSchema,
} from '@schuettflix/interfaces';
import { PaymentTermsSettings } from '@/clients/finance/types/FinanceSettings';
import { useDecorateOrganizationNamesByOrganizationIds } from '@/clients/organization/Organization';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetOrderById = () => {
    const { orderClient } = useHttpClientContext();
    return {
        getOrderById: (orderId: string) =>
            orderClient
                .get<z.input<typeof GetOrderResponseSchema>>(`/v1/order/${orderId}`)
                .then(res => GetOrderResponseSchema.parse(res.data)),
    };
};

export const useUpdateOrderWithClientInfo = () => {
    const { orderClient } = useHttpClientContext();
    return {
        updateOrderWithClientInfo: (payload: z.input<typeof PostCustomerInfoRequestSchema>, orderId: string) =>
            orderClient
                .post<z.input<typeof PostCustomerInfoResponseSchema>>(`/v1/order/${orderId}/customer-info`, payload)
                .then(res => PostCustomerInfoResponseSchema.parse(res.data)),
    };
};

export const useUpdateOrderWithUpdatedClientInfo = () => {
    const { orderClient } = useHttpClientContext();
    return {
        updateOrderWithUpdatedClientInfo: (payload: z.input<typeof PatchCustomerInfoRequestSchema>, orderId: string) =>
            orderClient
                .patch<
                    z.input<typeof PatchCustomerInfoResponseSchema>
                >(`/v1/order/${orderId}/customer-info/${payload.id}`, payload)
                .then(res => PatchCustomerInfoResponseSchema.parse(res.data)),
    };
};

export const useSubmitOrder = () => {
    const { orderClient } = useHttpClientContext();
    return {
        submitOrder: (orderId: string) =>
            orderClient
                .patch<z.input<typeof SubmitOrderResponseSchema>>(`/v1/order/${orderId}/submit`)
                .then(res => SubmitOrderResponseSchema.parse(res.data)),
    };
};

export const useGetOrderSummaryById = () => {
    const { orderClient } = useHttpClientContext();
    const { decorateOrganizationNamesByOrganizationIds } = useDecorateOrganizationNamesByOrganizationIds();

    return {
        getOrderSummaryById: async (orderId: string) => {
            const orderSummary = await orderClient
                .get<z.input<typeof GetOrderSummaryResponseSchema>>(`/v1/order/${orderId}/order-summary`)
                .then(res => {
                    return GetOrderSummaryResponseSchema.parse(res.data);
                });
            return decorateOrganizationNamesByOrganizationIds(orderSummary.organizations);
        },
    };
};

export type GetFulfillmentSummaryPayload = z.input<typeof GetFulfillmentSummaryRequestSchema>;
export const useGetFulfillmentSummaryById = () => {
    const { orderClient } = useHttpClientContext();
    const { decorateOrganizationNamesByOrganizationIds } = useDecorateOrganizationNamesByOrganizationIds();

    return {
        getFulfillmentSummaryById: async (orderId: string, payload: GetFulfillmentSummaryPayload) => {
            const orderSummary = await orderClient
                .post(`/v1/order/${orderId}/fulfillment-summary`, payload)
                .then(res => {
                    return GetFulfillmentSummaryResponseSchema.parse(res.data);
                });

            return decorateOrganizationNamesByOrganizationIds(orderSummary.organizations);
        },
    };
};

export const useUpdateOrganizationFinanceSettings = () => {
    const { orderClient } = useHttpClientContext();
    return {
        updateOrganizationFinanceSettings: (
            payload: z.input<typeof PatchPaymentTermRequestSchema>,
            organizationId: number,
            referenceId: string
        ) =>
            orderClient
                .patch<
                    z.input<typeof PatchPaymentTermRequestSchema>
                >(`/v1/order/${referenceId}/organization/${organizationId}/payment-term`, payload)
                .then(res => PatchPaymentTermResponseSchema.parse(res.data)),
    };
};

export const useGetPaymentTerms = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getPaymentTerms: () =>
            monolithClient.get<PaymentTermsSettings>(`/finance/v1/platform/payment-terms`).then(res => res.data),
    };
};

export const useFulfillOrder = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        fulfillOrder: (orderId: string, payload: z.input<typeof PostOrderFulfillRequestSchema>) => {
            return customRequestClient.post(`/v1/order-fulfill/${orderId}`, payload);
        },
    };
};

export const useCancelOrder = () => {
    const { customRequestClient } = useHttpClientContext();
    return {
        cancelOrder: (orderId: string) => customRequestClient.delete(`/v1/order-fulfill/${orderId}`),
    };
};
