import { GrabIcon, CopyIcon, DeleteIcon, PlusIcon } from '@schuettflix/icons-react';
import { Combobox, ComboboxOption, TextField } from '@schuettflix/react-components';
import { Control, Controller, useController, UseFormRegister, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFulfillmentContext } from '../context/FulfillmentContext';
import {
    IndividualLineItem,
    IndividualLineItemSchema,
    PartialIndividualLineItemInput,
} from '../shared/IndividualLineItemSchema';
import { DatePicker, NumberField } from '@schuettflix/planum-react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_UNIT_LABEL, PRODUCT_CATEGORY_LABEL } from '@schuettflix/interfaces';
import { cn } from '@/shared/utils/cn';
import { useFulfillmentSectionsErrors } from '../hooks/useFulfillmentSectionsErrors';
import { useSectionsValidity } from '../hooks/useSectionsValidity';
import { isKeyOf } from '@/shared/utils/isKeyOf';
import { useFilterOrganizations } from '@/clients/organization/useOrganization';
import { ProductFallbackDataMap } from '../types/ProductFallbackDataMap';
import { getFlattenedAndTranslatedZodErrorMessages } from '../utils/getFlattenedAndTranslatedZodErrorMessages';
import {
    ALLOWED_MIME_TYPE,
    MAX_FILE_SIZE_IN_BYTES,
    useFulfillmentUploadContext,
} from '../context/FulfillmentUploadContext';
import { useToasterContext } from '@/shared/context/ToasterContext';
import { asyncDelay } from '@/shared/utils/async';
import { toDatePickerValue } from '@/shared/utils/toDatePickerValue';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDuplicateCheck } from '../hooks/useDuplicateCheck';
import { useDuplicateCheckContext } from '../context/DuplicateCheckContext';

type IndividualLineItemFormProps = {
    id: string;
    control: Control<{ items: PartialIndividualLineItemInput[] }>;
    register: UseFormRegister<{ items: PartialIndividualLineItemInput[] }>;
    index: number;
    onRemove: () => void;
    disableActions?: boolean;
    productFallbackDataMap: ProductFallbackDataMap;
    forceValidation: boolean;
    onCopy: (lineItem: PartialIndividualLineItemInput) => void;
};
export const IndividualLineItemForm: React.FC<IndividualLineItemFormProps> = ({
    id,
    control,
    index,
    onRemove,
    disableActions,
    productFallbackDataMap,
    register,
    forceValidation,
    onCopy,
}) => {
    const { t } = useTranslation();
    const { isSubmitted, products, productGroup, documents } = useFulfillmentContext();
    const { addUploads } = useFulfillmentUploadContext();
    const { addToast } = useToasterContext();

    const {
        attributes: sortableAttributes,
        listeners: sortableListeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        disabled: disableActions,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    //////////////////////////////////////////
    // Data for inputs                      //
    //////////////////////////////////////////

    const fieldValues = useWatch({
        control,
        name: `items.${index}`,
    });

    const relevantPartnerIds = [
        ...products.flatMap(product => product.quote?.partnerOrganizationId),
        ...Object.values(productFallbackDataMap).map(p => p.partnerId),
    ];
    const { data: relevantPartnerList } = useFilterOrganizations({
        ids: [...new Set([...relevantPartnerIds].flatMap(id => (id ? String(id) : [])))],
    });
    const productNameOptions: ComboboxOption<string>[] = products.flatMap((product, index) =>
        (productGroup.type === 'MERCHANT' || productGroup.type === 'FRANCO') && product.category === 'TRANSPORT'
            ? [] // for merchant and franco, we don't allow the selection of transports
            : {
                  value: product.id,
                  label:
                      `${index + 1}. ` +
                      (product.quote?.name ||
                          productFallbackDataMap[product.id]?.productName ||
                          `[${t(PRODUCT_CATEGORY_LABEL[product.category])}]`),
                  description: relevantPartnerList?.items.find(
                      org =>
                          org.id === product.quote?.partnerOrganizationId ||
                          productFallbackDataMap[product.id]?.partnerId
                  )?.name,
              }
    );

    const documentUrlsOptions: ComboboxOption<string>[] = documents.map(document => ({
        value: document.url,
        label: document.fileName,
    }));
    const onDocumentUrlsFileInputChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            // check if files are present
            if (!event?.target?.files) return;

            // reset field value (so the user can upload the same file again)
            const files = [...event.target.files];
            event.target.value = '';

            // check if files are too big
            const tooBigFiles = files.filter(file => file.size > MAX_FILE_SIZE_IN_BYTES);
            if (tooBigFiles.length) {
                addToast({
                    title: t('fulfillment.documentUpload.errors.fileSize', { size: 20 }),
                    type: 'error',
                });
                return;
            }

            // upload files
            const uploadedFiles = await addUploads(files);

            // wait for the next render cycle to ensure the uploaded file
            // is available in the documents list
            await asyncDelay(0);

            const successfulUploads = uploadedFiles.filter(upload => upload.status === 'uploaded');
            return successfulUploads;
        },
        [addUploads, addToast, t]
    );

    const productUnit =
        products.find(product => product.id === fieldValues.productId)?.quote?.unit ||
        (fieldValues.productId && productFallbackDataMap[fieldValues.productId]?.unit);
    const unit = productUnit ? t(SUPPORTED_UNIT_LABEL[productUnit]) : '';

    const referenceDocumentPlaceholder = useMemo(() => {
        switch (productUnit) {
            case 'TON':
            case 'KILOGRAM':
            case 'CUBIC_METER':
                return t('product.lineItemListing.referenceDocumentWeighingProof');
            case 'HOUR':
            case 'MINUTE':
            case 'DAY':
                return t('product.lineItemListing.referenceDocumentTimeProof');
            default:
                return t('product.lineItemListing.referenceDocument');
        }
    }, [productUnit, t]);

    //////////////////////////////////////////
    // Validation                           //
    //////////////////////////////////////////

    const internalErrors = useMemo(() => {
        if (Object.values(fieldValues).some(v => !!v) || forceValidation) {
            return (
                getFlattenedAndTranslatedZodErrorMessages({
                    schema: IndividualLineItemSchema,
                    input: fieldValues,
                    errorTranslationMap: {
                        productId: t('product.fulfillment.errorMessages.productId.required'),
                        amount: {
                            invalid_type: t('product.fulfillment.errorMessages.amount.required'),
                            too_small: t('product.fulfillment.errorMessages.amount.minValue'),
                        },
                        serviceDate: t('product.fulfillment.errorMessages.serviceDate.invalid'),
                    },
                    shouldUseIssueCodeAsFallback: true,
                })?.fieldErrors ?? null
            );
        } else {
            return null;
        }
    }, [fieldValues, forceValidation, t]);
    const visibleErrors = isSubmitted ? internalErrors : null;

    type FieldName = keyof IndividualLineItem;
    const scrollToField = useCallback(
        (fieldNameOrElement: FieldName | HTMLElement | null) => {
            if (!fieldNameOrElement) return;

            if (typeof fieldNameOrElement === 'string')
                return document
                    .querySelector(`[name="items.${index}.${fieldNameOrElement}"]`)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'center' });

            fieldNameOrElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        [index]
    );

    const productIdRef = useRef<HTMLInputElement>(null);
    const amountRef = useRef<HTMLInputElement>(null);
    const serviceDateRef = useRef<HTMLElement>(null);
    const documentUrlsRef = useRef<HTMLInputElement>(null);
    const scrollToElementMap: Record<FieldName, () => void> = {
        productId: () => scrollToField(productIdRef.current),
        amount: () => scrollToField(amountRef.current),
        serviceDate: () => scrollToField(serviceDateRef.current),
        referenceDocument: () => scrollToField('referenceDocument'),
        licensePlate: () => scrollToField('licensePlate'),
        documentUrls: () => scrollToField(documentUrlsRef.current),
    };
    useFulfillmentSectionsErrors(
        Object.entries(internalErrors || {}).flatMap(([key, value]) => {
            if (!isKeyOf(internalErrors || {}, key)) return [];
            return {
                field: `lineItems.${index}.${key}`,
                message: value[0],
                scrollToElement: () => scrollToElementMap[key](),
            };
        })
    );
    useSectionsValidity(`items.${index}`, !internalErrors);

    //////////////////////////////////////////
    // Duplicate Check                      //
    //////////////////////////////////////////

    const { duplicateCheckState, runDuplicateCheck } = useDuplicateCheck({ individualLineItem: fieldValues, index });

    //////////////////////////////////////////
    // Side effects                         //
    //////////////////////////////////////////

    // rerun duplicate check on unit or partner change
    const { productDataMap } = useDuplicateCheckContext();
    const currentPartnerId = fieldValues.productId && productDataMap[fieldValues.productId]?.partnerId;
    useEffect(() => {
        runDuplicateCheck();
        // we don't want to run the duplicate check when runDuplicateCheck changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productUnit, currentPartnerId]);

    // Ensure we have valid product ids, empty invalid ones
    const {
        field: { onChange: setProductId },
    } = useController({ name: `items.${index}.productId`, control });
    useEffect(() => {
        if (fieldValues.productId && !products.find(product => product.id === fieldValues.productId)) {
            setProductId(undefined);
        }
    }, [fieldValues, products, setProductId]);

    // Ensure we have valid document urls, empty invalid ones
    const {
        field: { onChange: setDocumentUrls },
    } = useController({ name: `items.${index}.documentUrls`, control });
    useEffect(() => {
        if (fieldValues.documentUrls && !documents.find(document => document.url === fieldValues.documentUrls?.[0])) {
            setDocumentUrls(undefined);
        }
    }, [fieldValues, documents, setDocumentUrls]);

    return (
        <div>
            <div ref={setNodeRef} style={style} className="flex w-full animate-[pulse.6s_ease-in-out] gap-4">
                <Controller
                    name={`items.${index}.productId`}
                    control={control}
                    render={({ field: { ref: _ref, ...field } }) => (
                        <div className="min-w-0 flex-1">
                            <Combobox
                                {...field}
                                onBlur={runDuplicateCheck}
                                options={productNameOptions}
                                value={field.value ?? null}
                                placeholder={t('product.lineItemListing.productName')}
                                variant="sm"
                                errorMessage={visibleErrors?.productId?.[0]}
                            />
                            <span ref={productIdRef} />
                        </div>
                    )}
                />
                <Controller
                    name={`items.${index}.amount`}
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <NumberField
                            {...field}
                            value={field.value}
                            placeholder={t('product.lineItemListing.amount')}
                            aria-label={t('product.lineItemListing.amount')}
                            stature="sm"
                            trailingSlot={<NumberField.Suffix>{unit}</NumberField.Suffix>}
                            onChange={value => {
                                onChange(Number.isNaN(value) ? null : value);
                            }}
                            onBlur={async () => {
                                await asyncDelay(0);
                                runDuplicateCheck();
                            }}
                            isInvalid={!!visibleErrors?.amount?.[0]}
                            formatOptions={{
                                maximumFractionDigits: 3,
                            }}
                            className="w-[129px] [&_.planum-input-module-trailingSlot:has(>*:empty)]:max-w-fit"
                            errorMessage={visibleErrors?.amount?.[0]}
                            ref={amountRef}
                        />
                    )}
                />
                <TextField
                    {...register(`items.${index}.referenceDocument`)}
                    onBlur={runDuplicateCheck}
                    placeholder={referenceDocumentPlaceholder}
                    errorMessage={visibleErrors?.referenceDocument?.[0]}
                    variant="sm"
                    className="flex-1"
                    onKeyDown={e => {
                        if (e.key === ',' || e.key === '+' || e.key === ' ') {
                            e.preventDefault();
                        }
                    }}
                />
                <TextField
                    {...register(`items.${index}.licensePlate`)}
                    placeholder={t('product.lineItemListing.licensePlate')}
                    errorMessage={visibleErrors?.licensePlate?.[0]}
                    variant="sm"
                    className="flex-1"
                />
                <div className="w-[174px] [&_.planum-datePicker-module-trigger]:min-h-12 [&_.planum-datePicker-module-trigger]:min-w-full">
                    <Controller
                        name={`items.${index}.serviceDate`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                value={toDatePickerValue(value)}
                                onChange={date => {
                                    onChange(date?.toString());
                                }}
                                onBlur={runDuplicateCheck}
                                label={t('product.lineItemListing.serviceDate')}
                                stature="sm"
                            />
                        )}
                    />
                    <span ref={serviceDateRef} />
                    {!!visibleErrors?.serviceDate?.[0] && (
                        <span className="text-critical font-copy-sm mt-2">{visibleErrors?.serviceDate?.[0]}</span>
                    )}
                </div>
                <Controller
                    name={`items.${index}.documentUrls`}
                    control={control}
                    render={({ field: { ref: _ref, onChange, value, ...field } }) => (
                        <div className="min-w-0 flex-1">
                            <Combobox
                                {...field}
                                options={documentUrlsOptions}
                                value={value?.[0] ?? null}
                                onChange={value => (value ? onChange([value]) : onChange(undefined))}
                                placeholder={t('product.lineItemListing.documentUrls')}
                                variant="sm"
                                errorMessage={visibleErrors?.documentUrls?.[0]}
                                renderActionButton={() => (
                                    <>
                                        <PlusIcon />
                                        {t('product.lineItemListing.uploadDocumentLabel')}
                                    </>
                                )}
                                onActionButton={() => documentUrlsRef.current?.click()}
                                allowDeselect
                            />
                            <input
                                type="file"
                                accept={ALLOWED_MIME_TYPE}
                                hidden
                                ref={documentUrlsRef}
                                onChange={event =>
                                    onDocumentUrlsFileInputChange(event).then(
                                        successfulUploads =>
                                            successfulUploads?.[0]?.remotePath &&
                                            onChange([successfulUploads[0].remotePath])
                                    )
                                }
                            />
                        </div>
                    )}
                />
                <div className="flex shrink items-center gap-4">
                    <GrabIcon
                        {...sortableAttributes}
                        {...sortableListeners}
                        disabled={disableActions}
                        role={disableActions ? 'none' : 'button'}
                        className="focus-within:outline-none"
                    />
                    <CopyIcon
                        className={cn(!disableActions && 'cursor-pointer')}
                        disabled={disableActions}
                        onClick={() => {
                            if (disableActions) {
                                return;
                            }
                            const { amount: _, referenceDocument: __, ...values } = fieldValues;
                            onCopy(values);
                        }}
                    />
                    <DeleteIcon
                        className={cn(!disableActions && 'cursor-pointer')}
                        disabled={disableActions}
                        onClick={() => !disableActions && onRemove()}
                    />
                </div>
            </div>
            {duplicateCheckState && <span className="font-copy-sm px-2 pt-2">{duplicateCheckState.message}</span>}
        </div>
    );
};
