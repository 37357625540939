import { Select } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { useMarketContext } from '@/shared/context/MarketContext.tsx';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { MarketCode } from '@/shared/types/MarketCode.ts';
import { useIdTokenContext } from '@/shared/context/IdTokenContext.tsx';

export type MarketSelectorProps = {
    disabled?: boolean;
};

export const MarketSelector = ({ disabled = false }: MarketSelectorProps) => {
    const { getAvailableMarketCodes } = useIdTokenContext();
    const { marketCode } = useMarketContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const availableMarketCodes = getAvailableMarketCodes();

    const handleChange = useCallback(
        async (selectedMarket: MarketCode | null) => {
            if (selectedMarket && selectedMarket !== marketCode) {
                await navigate(`/?marketCode=${selectedMarket}`);
            }
        },
        [marketCode, navigate]
    );

    const options = useMemo(
        () =>
            availableMarketCodes.map(marketCode => ({
                value: marketCode,
                label: marketCode,
            })),
        [availableMarketCodes]
    );

    if (options.length <= 1) {
        return null;
    }

    return (
        <div>
            <h3 className="font-copy-md-strong mb-2">{t('sections.platformSelection.description')}</h3>
            <Select<MarketCode>
                value={marketCode}
                onChange={handleChange}
                options={options}
                label={t('sections.platformSelection.platformLabel')}
                disabled={disabled}
            />
        </div>
    );
};
