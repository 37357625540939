import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import {
    FilterOptionsOrganization,
    useFilterOrganizations as useFilterOrganizationsClient,
    useGetOrganizationById,
} from './Organization';

export function useOrganization(id: number | undefined | null) {
    const { getOrganizationById } = useGetOrganizationById();

    return useQuery({
        queryKey: ['organization', id!],
        queryFn: () => getOrganizationById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
    });
}

export function useOrganizationSuspenseQuery(id: number | undefined | null) {
    const { getOrganizationById } = useGetOrganizationById();

    return useSuspenseQuery({
        queryKey: ['organization', id],
        queryFn: () => (id ? getOrganizationById(id) : null),
        refetchOnWindowFocus: false,
    });
}

export function useFilterOrganizations(filterOptions: FilterOptionsOrganization, enabled = true) {
    const { filterOrganizations } = useFilterOrganizationsClient();

    return useQuery({
        queryKey: ['organizations', filterOptions],
        queryFn: () => filterOrganizations(filterOptions),
        refetchOnWindowFocus: false,
        enabled,
        staleTime: 60000,
    });
}

export function useOrganizationName(id: number | undefined | null) {
    const { getOrganizationById } = useGetOrganizationById();

    return useQuery({
        queryKey: ['organization', id!],
        queryFn: () => getOrganizationById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
        select: data => data?.name,
    });
}
