import {
    GetPositionQuoteResponseSchema,
    PatchPositionQuoteRequestSchema,
    PatchPositionQuoteResponseSchema,
    PostPositionQuoteRequestSchema,
} from '@schuettflix/interfaces';
import { z } from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useCreatePositionQuote = () => {
    const { customRequestClient } = useHttpClientContext();

    return {
        createPositionQuote: async ({
            positionQuote,
        }: {
            positionQuote: z.infer<typeof PostPositionQuoteRequestSchema>;
        }) => {
            return customRequestClient
                .post<z.infer<typeof PostPositionQuoteRequestSchema>>(`/v1/position-quote`, positionQuote)
                .then(res => GetPositionQuoteResponseSchema.parse(res.data));
        },
    };
};

export const useGetPositionQuoteByPositionProductId = () => {
    const { customRequestClient } = useHttpClientContext();

    return {
        getPositionQuoteByPositionProductId: async ({
            positionProductId,
        }: {
            positionProductId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
        }) => {
            return customRequestClient
                .get<z.infer<typeof GetPositionQuoteResponseSchema>>(`/v1/position-quote/${positionProductId}`)
                .then(res => GetPositionQuoteResponseSchema.parse(res.data));
        },
    };
};

export const usePatchPositionQuoteByPositionProductId = () => {
    const { customRequestClient } = useHttpClientContext();

    return {
        patchPositionQuoteByPositionProductId: async ({
            positionProductId,
            positionQuote,
        }: {
            positionProductId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
            positionQuote: z.infer<typeof PatchPositionQuoteRequestSchema>;
        }) => {
            return customRequestClient
                .patch<void>(`/v1/position-quote/${positionProductId}`, positionQuote)
                .then(res => PatchPositionQuoteResponseSchema.parse(res.data));
        },
    };
};
