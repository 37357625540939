import { PostPositionGroupRequestSchema } from '@schuettflix/interfaces';
import { useCreatePositionProductGroup as useCreatePositionProductGroupClient } from './position';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

export const useCreatePositionProductGroup = (positionId: string) => {
    const queryClient = useQueryClient();
    const { createPositionProductGroup } = useCreatePositionProductGroupClient();

    return useMutation({
        mutationKey: ['createPositionProductGroup', positionId],
        mutationFn: async (payload: { productGroup: z.infer<typeof PostPositionGroupRequestSchema> }) => {
            return createPositionProductGroup(positionId, payload.productGroup);
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};
