import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetQuotesByOrder as useGetQuotesByOrderClient } from './quotes.ts';

export const useGetQuotesByOrder = (orderId?: string) => {
    const { getQuotesByOrder } = useGetQuotesByOrderClient();

    return useSuspenseQuery({
        queryKey: ['quotes-by-order', orderId],
        queryFn: () => (orderId ? getQuotesByOrder(orderId) : null),
    });
};
