import axios, { AxiosInstance } from 'axios';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { MarketCode } from '@/shared/types/MarketCode.ts';
import { environment } from '@/environment.ts';
import { useIdTokenContext } from '@/shared/context/IdTokenContext.tsx';

export type HttpClientContextType = {
    constructionProjectClient: AxiosInstance;
    customRequestClient: AxiosInstance;
    fulfillmentClient: AxiosInstance;
    monolithClient: AxiosInstance;
    orderClient: AxiosInstance;
    positionClient: AxiosInstance;
    getMarketScopedOrderClient: (marketCode: MarketCode) => AxiosInstance;
};

type HttpClientContextProviderProps = PropsWithChildren & {
    marketCode: MarketCode;
};

type Headers = {
    'Accept-Language': string;
    Authorization: string;
    'X-Client-Version': string;
    'X-Operating-Market': MarketCode;
};

const HttpClientContext = createContext<HttpClientContextType | undefined>(undefined);

function createClient(baseUrl: string, headers: Headers) {
    return axios.create({
        baseURL: baseUrl,
        headers: { ...headers },
    });
}

export function HttpClientContextProvider({ children, marketCode }: HttpClientContextProviderProps) {
    const { getIdToken } = useIdTokenContext();

    let tokenValue: string = '';
    try {
        tokenValue = getIdToken();
    } catch (e) {
        throw new Error('Unable to render HttpClientContextProvider', { cause: e });
    }

    const headers = useMemo<Headers>(
        () => ({
            'Accept-Language': 'de-DE',
            Authorization: `Bearer ${tokenValue}`,
            'X-Client-Version': environment.FRONTEND_MONOLITH_APP_VERSION,
            'X-Operating-Market': marketCode,
        }),
        [tokenValue, marketCode]
    );

    const constructionProjectClient = useMemo(
        () => createClient(environment.CONSTRUCTION_PROJECT_BASE_URL, headers),
        [headers]
    );
    const customRequestClient = useMemo(
        () => createClient(environment.CUSTOM_REQUEST_SERVICE_BASE_URL, headers),
        [headers]
    );
    const fulfillmentClient = useMemo(() => createClient(environment.FULFILLMENT_SERVICE_BASE_URL, headers), [headers]);
    const monolithClient = useMemo(() => createClient(environment.MONOLITH_BASE_URL, headers), [headers]);
    const orderClient = useMemo(() => createClient(environment.ORDER_SERVICE_BASE_URL, headers), [headers]);
    const positionClient = useMemo(() => createClient(environment.POSITION_SERVICE_BASE_URL, headers), [headers]);

    const getMarketScopedOrderClient = useCallback(
        (marketCode: MarketCode): AxiosInstance => {
            return createClient(environment.ORDER_SERVICE_BASE_URL, { ...headers, 'X-Operating-Market': marketCode });
        },
        [headers]
    );

    return (
        <HttpClientContext.Provider
            value={{
                constructionProjectClient,
                customRequestClient,
                fulfillmentClient,
                monolithClient,
                orderClient,
                positionClient,
                getMarketScopedOrderClient,
            }}
        >
            {children}
        </HttpClientContext.Provider>
    );
}

export function useHttpClientContext() {
    const context = useContext(HttpClientContext);
    if (!context) {
        throw new Error('HttpClientContext must be provided!');
    }
    return context;
}
