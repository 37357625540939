import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DuplicateCheckHook } from './useDuplicateCheck';
import { useQueryClient } from '@tanstack/react-query';
import { useReferenceDocumentDuplicateQueryOptions } from '@/CustomRequestProductChannel/hooks/useReferenceDocumentDuplicateQuery';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag';
import { useLatest } from 'react-use';

export const useDuplicateCheckCustomRequest: DuplicateCheckHook = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const createReferenceDocumentDuplicateQueryOptions = useReferenceDocumentDuplicateQueryOptions();
    const isDuplicateCheckEnabledRef = useLatest(useFeatureFlag('duplicateCheckInCustomRequest'));
    return useCallback(
        async ({ partnerId, amount, referenceDocument, serviceDate, unit }) => {
            if (!isDuplicateCheckEnabledRef.current) return;
            if (!unit || !partnerId) return;
            const { usedBy } = await queryClient.fetchQuery(
                createReferenceDocumentDuplicateQueryOptions({
                    partnerOrganizationId: partnerId,
                    quantity: amount,
                    referenceDocument,
                    serviceDate: new Date(serviceDate),
                    unit,
                })
            );
            if (!usedBy.length) return;
            const { orderNumber, transportNumber } = usedBy[0];
            return {
                message: transportNumber
                    ? t('product.lineItemListing.duplicateCheck.customRequestWithOrderAndTransport', {
                          orderNumber,
                          transportNumber,
                      })
                    : t('product.lineItemListing.duplicateCheck.customRequestWithOrder', { orderNumber }),
            };
        },
        [createReferenceDocumentDuplicateQueryOptions, isDuplicateCheckEnabledRef, t, queryClient]
    );
};
