import {
    GetFulfillmentProductGroupsResponseSchema,
    GetFulfillmentProductsResponseSchema,
    GetOrderStatusResponse,
    GetOrderStatusResponseSchema,
    ListResponseBody,
    NestedFulfillmentProductGroupResponseSchema,
    NestedFulfillmentProductResponseItemSchema,
    PatchFulfillmentProductConfigurationRequest,
    PatchFulfillmentProductConfigurationResponse,
    PatchFulfillmentProductConfigurationResponseSchema,
    PatchFulfillmentProductConfigurationUrl,
    PostFulfillmentProductRequest,
    PostFulfillmentProductResponse,
    PostFulfillmentProductResponseSchema,
} from '@schuettflix/interfaces';
import z from 'zod';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const useGetOrderStatus = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        getOrderStatus: async (orderId: string) => {
            return fulfillmentClient
                .get<GetOrderStatusResponse>(`/v1/orders/${orderId}/status`)
                .then(response => GetOrderStatusResponseSchema.parse(response.data));
        },
    };
};

export const useGetFulfillmentProductsByOrderIdApi = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        getFulfillmentProductsByOrderId: async (orderId: string) => {
            return fulfillmentClient
                .get<
                    ListResponseBody<z.input<typeof NestedFulfillmentProductResponseItemSchema>>
                >(`/v1/product-fulfillment/${orderId}`)
                .then(response => GetFulfillmentProductsResponseSchema.parse(response.data.items));
        },
    };
};

export const usePatchFulfillmentProductConfiguration = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        patchFulfillmentProductConfiguration: async ({
            fulfillmentProductId,
            fulfillmentProductConfiguration,
        }: {
            fulfillmentProductId: PatchFulfillmentProductConfigurationUrl['fulfillmentProductId'];
            fulfillmentProductConfiguration: PatchFulfillmentProductConfigurationRequest;
        }) => {
            return fulfillmentClient
                .patch<PatchFulfillmentProductConfigurationResponse>(
                    `/v1/product-fulfillment/${fulfillmentProductId}`,
                    fulfillmentProductConfiguration
                )
                .then(response => PatchFulfillmentProductConfigurationResponseSchema.parse(response.data));
        },
    };
};

export const useGetFulfillmentProductGroupsByOrderIdApi = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        getFulfillmentProductGroupsByOrderId: async (orderId: string) => {
            return fulfillmentClient
                .get<
                    ListResponseBody<z.input<typeof NestedFulfillmentProductGroupResponseSchema>>
                >(`/v1/product-group-fulfillment/order/${orderId}`)
                .then(response => GetFulfillmentProductGroupsResponseSchema.parse(response.data.items));
        },
    };
};

export const usePostFulfillmentProduct = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        postFulfillmentProduct: async (fulfillmentProduct: PostFulfillmentProductRequest) => {
            return fulfillmentClient
                .post<PostFulfillmentProductResponse>(`/v1/product-fulfillment`, fulfillmentProduct)
                .then(response => PostFulfillmentProductResponseSchema.parse(response.data));
        },
    };
};

export const useDeleteFulfillmentProduct = () => {
    const { fulfillmentClient } = useHttpClientContext();
    return {
        deleteFulfillmentProduct: async ({ fulfillmentProductId }: { fulfillmentProductId: string }) => {
            await fulfillmentClient.delete(`/v1/product-fulfillment/${fulfillmentProductId}`);
        },
    };
};
