import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const PRODUCT_CATEGORY_SCHEMA = order.orderService.versions.v1.schemas.PRODUCT_CATEGORY_SCHEMA;
export type PRODUCT_CATEGORY = z.infer<typeof PRODUCT_CATEGORY_SCHEMA>;

export const PRODUCT_CATEGORY_LABEL = {
    MATERIAL: 'product.category.label.material',
    TRANSPORT: 'product.category.label.transport',
    MATERIAL_SERVICE: 'product.category.label.custom',
    TRANSPORT_SERVICE: 'product.category.label.custom',
} as const satisfies Record<PRODUCT_CATEGORY, string>;

export const PRODUCT_NAME_PLACEHOLDER_LABEL = {
    MATERIAL: 'product.category.material',
    TRANSPORT: 'product.category.transport',
    MATERIAL_SERVICE: 'product.category.custom',
    TRANSPORT_SERVICE: 'product.category.custom',
} as const satisfies Record<PRODUCT_CATEGORY, string>;

export const PRODUCT_CATEGORIES = {
    MATERIAL: PRODUCT_CATEGORY_SCHEMA.enum.MATERIAL,
    TRANSPORT: PRODUCT_CATEGORY_SCHEMA.enum.TRANSPORT,
    MATERIAL_SERVICE: PRODUCT_CATEGORY_SCHEMA.enum.MATERIAL_SERVICE,
    TRANSPORT_SERVICE: PRODUCT_CATEGORY_SCHEMA.enum.TRANSPORT_SERVICE,
} as const;
