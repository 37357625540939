import { useSuspenseQuery } from '@tanstack/react-query';
import { useGetOrderStatus } from '@/clients/fulfillment/fulfillment.ts';

export const useOrderStatus = (orderId: string) => {
    const { getOrderStatus } = useGetOrderStatus();
    return useSuspenseQuery({
        queryKey: ['order-status', orderId],
        queryFn: () => getOrderStatus(orderId),
    });
};
