import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useId,
    useMemo,
    useState,
} from 'react';
import { z } from 'zod';
import {
    GetOrderResponseSchema,
    GetProductGroupListResponseSchema,
    PRODUCT_CATEGORY,
    PRODUCT_TYPES,
    SUPPORTED_UNITS,
    CURRENCY,
    FulfillmentProductConfiguration,
    LineItemListingSchema,
} from '@schuettflix/interfaces';
import { useOrder } from '@/clients/order/useOrder';
import { useOrderProductGroup } from '@/clients/orderProducts/useOrderProductGroup.ts';
import { Price } from '@schuettflix/conversion';
import { useNavigate } from 'react-router';
import { useGetQuotesByOrder } from '@/clients/quotes/useQuotes.ts';
import { useGetFulfillmentProductGroupByOrderId } from '@/clients/fulfillment/useFulfillmentProductGroup.ts';
import { mapToFulfillmentQuote } from '@/modules/fulfillment/utils/mapToFulfillmentQuote.ts';
import { FulfillmentDiffData } from '../hooks/useFulfillmentDiffCheck';

type LineItemListing = z.infer<typeof LineItemListingSchema>;

export const PRODUCT_SECTION_PREFIX = `product-section-item`;

export function getProductSectionName(productId: string) {
    return `${PRODUCT_SECTION_PREFIX}-${productId}`;
}

export type OrderType = z.infer<typeof GetOrderResponseSchema> | null;

export type FulfillmentQuote = {
    id: string;
    name: string;
    orderProductId: string;
    amount: number;
    unit: SUPPORTED_UNITS;
    currencyCode: CURRENCY;
    partnerOrganizationId: number | null;
    orderingOrganizationId: number;
    platformOrganizationId: number;
    purchasePrice: Price;
    purchaseTaxClassId: string;
    salesPrice: Price;
    salesTaxClassId: string;
    serviceDate: string;
};

export type FulfillmentProduct = {
    id: string;
    category: PRODUCT_CATEGORY;
    type: PRODUCT_TYPES;
    templateId?: string;
    inOriginalOrder: boolean;
    positionProductId: string | null;
    quote?: FulfillmentQuote;
    fulfillmentProductConfiguration: FulfillmentProductConfiguration | null;
};

export type OrderFulfillmentProduct = Omit<FulfillmentProduct, 'quote'> & { quote: FulfillmentQuote };

export type UpdateOrderFulfillmentProduct = Partial<Omit<OrderFulfillmentProduct, 'id'>> & { id: string };

export type FormError = {
    field: string;
    message: string;
    scrollToElement: () => void;

    /** An optional arbitrary id that can help identifying
     * if the error belongs to the current section / component instance
     */
    sectionId?: string;
};

type FulfillmentContextType = {
    order: OrderType;
    setOrder: (value: OrderType) => void;
    products: FulfillmentProduct[];
    orderFulfillmentProducts: OrderFulfillmentProduct[];
    fulfillmentProducts: FulfillmentProduct[];
    documents: DocumentType[];
    setDocuments: (values: DocumentType[]) => void;
    errors: FormError[];
    setErrors: Dispatch<SetStateAction<FormError[]>>;
    updateOrderFulfillmentProduct: (product: UpdateOrderFulfillmentProduct) => void;
    /** Whether the form submit button was clicked */
    isSubmitted: boolean;
    setSubmitted: (value: boolean) => void;
    invalidSections: string[];
    setInvalidSections: Dispatch<SetStateAction<string[]>>;
    isPageValid: boolean;
    canSubmit: boolean;
    setCanSubmitStack: Dispatch<SetStateAction<Record<string, boolean>>>;
    productGroup: z.input<typeof GetProductGroupListResponseSchema>;
    quotesByOrder: FulfillmentQuote[];
    /**
     * Will be used when "individual line item listing" mode is enabled.
     * If the mode is not enabled, this value will be null.
     */
    lineItemListing: LineItemListing[] | null;
    /**
     * Will be used when "individual line item listing" mode is enabled.
     * If the mode is not enabled, the line items will be auto-inferred from the products.
     */
    getLineItemListingWithProductFallback: () => LineItemListing[];
    setLineItemListing: Dispatch<SetStateAction<LineItemListing[] | null>>;
    fulfillmentDiffDataStack: Record<string, FulfillmentDiffData>;
    setFulfillmentDiffDataStack: Dispatch<SetStateAction<Record<string, FulfillmentDiffData>>>;
};

type DocumentType = {
    url: string;
    fileName: string;
};

const FulfillmentContext = createContext<FulfillmentContextType | undefined>(undefined);

export const FulfillmentContextProvider: React.FC<{ orderId: string; children: ReactNode }> = ({
    orderId,
    children,
}) => {
    const navigate = useNavigate();

    const { data: orderData } = useOrder(orderId, true);
    const { data: productGroupsData } = useOrderProductGroup(orderId, true);
    const { data: fulfillmentProductGroup } = useGetFulfillmentProductGroupByOrderId(orderId);
    const { data: quotes } = useGetQuotesByOrder(orderId);

    const [order, setOrder] = useState<OrderType>(null);
    const [documents, setDocuments] = useState<DocumentType[]>([]);
    const [errors, setErrors] = useState<FormError[]>([]);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [invalidSections, setInvalidSections] = useState<string[]>([]);
    const [isPageValid, setIsPageValid] = useState<boolean>(true);
    const [canSubmitStack, setCanSubmitStack] = useState<Record<string, boolean>>({});
    const [fulfillmentDiffDataStack, setFulfillmentDiffDataStack] = useState<Record<string, FulfillmentDiffData>>({});

    const canSubmit = useMemo(() => {
        return Object.values(canSubmitStack).every(v => !!v);
    }, [canSubmitStack]);

    const [orderFulfillmentProducts, setOrderFulfillmentProducts] = useState<OrderFulfillmentProduct[]>([]);
    const fulfillmentProducts = useMemo<FulfillmentProduct[]>(() => {
        return (
            fulfillmentProductGroup?.products
                ?.filter(p => p.source === 'FULFILLMENT')
                .map(product => {
                    const quote = quotes?.find(quote => quote.orderProductId === product.id);

                    return {
                        id: product.id,
                        category: product.productCategory,
                        type: product.productType,
                        inOriginalOrder: false,
                        positionProductId: null,
                        fulfillmentProductConfiguration: product.fulfillmentProductConfiguration,
                        quote: quote ? mapToFulfillmentQuote(quote) : undefined,
                    };
                }) ?? []
        );
    }, [fulfillmentProductGroup?.products, quotes]);

    const quotesByOrder = quotes?.map(mapToFulfillmentQuote) ?? [];

    useEffect(() => {
        const orderProducts = fulfillmentProductGroup?.products?.filter(p => p.source === 'ORDER');

        if (!quotes || !orderProducts) {
            return;
        }
        setOrderFulfillmentProducts(
            orderProducts.map(product => {
                const quote = quotes?.find(quote => quote.orderProductId === product.id);

                if (!quote) {
                    throw new Error(`quote not found for original order product with Id: ${product.id}`);
                }

                return {
                    id: product.id,
                    category: product.productCategory,
                    type: product.productType,
                    inOriginalOrder: true,
                    positionProductId: null,
                    fulfillmentProductConfiguration: product.fulfillmentProductConfiguration,
                    quote: mapToFulfillmentQuote(quote),
                };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fulfillmentProductGroup?.products]);

    useEffect(() => {
        if (orderData?.status === 'DRAFT') {
            void navigate('/order/' + orderId);
        }
        if (orderData) {
            setOrder(orderData);
        }
    }, [orderData, orderId, navigate]);

    useEffect(() => {
        setIsPageValid(invalidSections.length === 0);
    }, [invalidSections]);

    const updateOrderFulfillmentProduct = (product: UpdateOrderFulfillmentProduct) => {
        setOrderFulfillmentProducts(previousProducts => {
            const productIndex = previousProducts.findIndex(p => p.id === product.id);
            const updatedProducts = [...previousProducts];
            updatedProducts[productIndex] = { ...updatedProducts[productIndex], ...product };

            return updatedProducts;
        });
    };

    const products = useMemo(() => {
        return [...orderFulfillmentProducts, ...fulfillmentProducts];
    }, [orderFulfillmentProducts, fulfillmentProducts]);

    const [lineItemListing, setLineItemListing] = useState<LineItemListing[] | null>(null);
    const getLineItemListingWithProductFallback = useCallback(() => {
        return (
            lineItemListing ??
            products.map(product =>
                LineItemListingSchema.parse({
                    ...product?.quote,
                    productId: product.id,
                    sortIndex: 0,
                })
            )
        );
    }, [lineItemListing, products]);

    return (
        <FulfillmentContext.Provider
            value={{
                order,
                setOrder,
                products,
                orderFulfillmentProducts,
                fulfillmentProducts,
                quotesByOrder,
                updateOrderFulfillmentProduct,
                documents,
                setDocuments,
                errors,
                setErrors,
                isSubmitted,
                setSubmitted,
                isPageValid,
                invalidSections,
                setInvalidSections,
                productGroup: productGroupsData,
                canSubmit,
                setCanSubmitStack,
                lineItemListing,
                setLineItemListing,
                getLineItemListingWithProductFallback,
                fulfillmentDiffDataStack,
                setFulfillmentDiffDataStack,
            }}
        >
            {children}
        </FulfillmentContext.Provider>
    );
};

/**
 * Hook to access the FulfillmentContext.
 * Throws an error if used outside of a FulfillmentContextProvider.
 *
 * @returns The context value.
 * @throws {Error} If used outside of a FulfillmentContextProvider.
 */
export const useFulfillmentContext = () => {
    const context = useMaybeFulfillmentContext();
    if (!context) {
        throw new Error('useFulfillmentContext must be used within a FulfillmentContext');
    }
    return context;
};

/**
 * Hook to access the FulfillmentContext.
 * Returns undefined if used outside of a FulfillmentContextProvider.
 *
 * @returns The context value or undefined.
 */
export const useMaybeFulfillmentContext = () => {
    const context = useContext(FulfillmentContext);
    const id = useId();

    const setCanSubmit = useCallback(
        (value: boolean) => {
            if (!context) {
                return;
            }
            context.setCanSubmitStack(previousStack => {
                return { ...previousStack, [id]: value };
            });
        },
        [context, id]
    );

    // add the entry to the canSubmitStack on mount and remove it on unmount
    useEffect(() => {
        if (!context) {
            return;
        }
        context.setCanSubmitStack(previousStack => {
            return { ...previousStack, [id]: true };
        });
        return () => {
            context.setCanSubmitStack(previousStack => {
                const { [id]: _, ...rest } = previousStack;
                return rest;
            });
        };
        // we only want to run this effect on mount and unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!context) return undefined;
    return { ...context, setCanSubmit };
};
