import { useCallback } from 'react';
import { environment } from '@/environment.ts';

/** The options for a toast that the monolith accepts */
type Toast = {
    /** The message to display in the toast */
    message: string;
    /** The type of toast to display */
    type: 'success' | 'error' | 'warning' | 'info';
};

/** The options for generating the URL */
type UrlArgs = {
    /** The path to append to the base URL */
    path?: string;
    /** The toast object to include as a query parameter in the URL */
    toast?: Toast;
    /** The query parameters to include in the URL */
    query?: Record<string, string>;
} & (
    | {
          /** The target where to open the url */
          target: 'newTab';
      }
    | {
          /** The target where to open the url */
          target?: 'currentTab';
          /** The method to use for the browser history (only relevant when `target: 'currentTab'`) */
          historyMethod?: 'push' | 'replace';
      }
);

/**
 * Custom hook to generate/open URLs for the monolith frontend.
 */
export const useMonolithFrontendUrl = ({ path = '', toast, query, ...args }: UrlArgs) => {
    const baseUrl = environment.MONOLITH_FE_URL;
    const target = args.target || 'currentTab';
    const historyMethod = ('historyMethod' in args && args.historyMethod) || 'push';

    const constructUrl = useCallback(
        ({ path: _path, toast: _toast, query: _query }: UrlArgs = {}) => {
            const finalPath = _path ?? path;
            const finalToast = _toast ?? toast;
            const finalQuery = { ...query, ..._query };
            const pathWithLeadingSlash = finalPath.startsWith('/') ? finalPath : `/${finalPath}`;
            const queryParam = new URLSearchParams({
                ...finalQuery,
                ...(finalToast ? { toast: JSON.stringify(finalToast) } : {}),
            });
            const queryParamString = queryParam.size ? `?${queryParam.toString()}` : '';

            return baseUrl + `${pathWithLeadingSlash}${queryParamString}`;
        },
        [baseUrl, path, toast, query]
    );

    const openUrl = useCallback(
        (args: UrlArgs = {}) => {
            const finalTarget = args.target || target;
            const finalHistoryMethod = ('historyMethod' in args && args.historyMethod) || historyMethod;
            const url = constructUrl(args);

            const targetMapping = {
                currentTab: '_self',
                newTab: '_blank',
            };

            if (finalTarget === 'currentTab' && finalHistoryMethod === 'push') {
                window.location.href = url;
            } else if (targetMapping[finalTarget]) {
                window.open(url, targetMapping[finalTarget]);
            } else {
                throw new Error(`Invalid target: ${finalTarget}`);
            }
        },
        [constructUrl, target, historyMethod]
    );

    return {
        /**
         * Opens the url in the current tab (`target: 'currentTab'`) or a new tab (`target: 'newTab'`).
         * Any given arguments will override the according argument from the hook.
         */
        openUrl,

        /** The generated URL */
        url: constructUrl(),
    };
};
