import type { FilterOptionsOrganization, FilterResultOrganization, Organization } from './types/organization';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export * from './types/organization';

export const useFilterOrganizations = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        filterOrganizations: (filterOptions: FilterOptionsOrganization) =>
            monolithClient
                .get<FilterResultOrganization>('/v3/organization', { params: filterOptions })
                .then(res => res.data),
    };
};

export const useGetOrganizationById = () => {
    const { monolithClient } = useHttpClientContext();
    return {
        getOrganizationById: (id: number) =>
            monolithClient.get<Organization>(`/v3/organization/${id}`).then(res => res.data),
    };
};

export const useDecorateOrganizationNamesByOrganizationIds = () => {
    const { getOrganizationById } = useGetOrganizationById();
    return {
        decorateOrganizationNamesByOrganizationIds: async <Organization extends { orgId: number }>(
            organizations: Organization[]
        ): Promise<{
            organizations: (Organization & { orgName: string })[];
        }> => {
            return {
                organizations: await Promise.all(
                    organizations.map(async org => {
                        const orgDetails = await getOrganizationById(org.orgId);
                        return {
                            ...org,
                            orgName: orgDetails.name,
                        };
                    })
                ),
            };
        },
    };
};
