import { z } from 'zod';
import { GET_LINE_ITEMS_SCHEMA } from '../product-channel/get-line-item.ts';

export const GetOrderLineItemsUrlQueryParametersSchema = z.strictObject({
    shouldExcludeCanceledQuotes: z.coerce.boolean().optional(),
});

export const GetOrderLineItemsUrlPathParametersSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const LineItemSchema = GET_LINE_ITEMS_SCHEMA;

// This probaly needs to be changed in the schema registry. By adding a new type for the list of line items.
// and to avoid doing that here.
export const LineItemListSchema = GET_LINE_ITEMS_SCHEMA.array();

export type LineItem = z.infer<typeof LineItemSchema>;

/**
 * validation schema for response payload
 */
export const GetLineItemListResponseSchema = LineItemSchema;

export type GetLineItemListResponse = z.infer<typeof GetLineItemListResponseSchema>;
