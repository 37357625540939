import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

interface Address {
    city: string;
    country: string;
    state: string;
    zip: string;
}

interface ContactNumber {
    countryCode: string;
    number: string;
}

interface Contact {
    email: string;
    mobile: ContactNumber;
    phone: ContactNumber;
}

interface Location {
    lat: number;
    lng: number;
    openLocationCode: string;
}

interface OpeningHour {
    end: string;
    start: string;
    weekday: string;
}

interface OpeningTime {
    dayOfWeek: number;
    end: string;
    start: string;
}

export interface FactoryItem {
    address: Address;
    contact: Contact;
    deliveryAllowed: boolean;
    disposalWeighingMethod: string;
    disposalWeighingTransmissionType: string;
    factoryNumber: string;
    id: number;
    location: Location;
    name: string;
    open: boolean;
    openingHours: OpeningHour[];
    openingTimes: OpeningTime[];
    pickupAllowed: boolean;
    supplierOrganizationId: number;
    usages: string[];
    weighingTransmissionType: string;
}

interface Filter {
    supplierId: number;
}

interface SupportedFilters {
    search: {
        type: string;
    };
    supplierId: {
        type: string;
    };
}

interface GetFactoriesByOrganizationIdResponse {
    items: FactoryItem[];
    filter: Filter;
    count: number;
    sortBy: string;
    sortDirection: string;
    supportedFilters: SupportedFilters;
    supportedSorts: string[];
}

export function useGetFactoriesByOrganizationId() {
    const { monolithClient } = useHttpClientContext();
    return {
        getFactoriesByOrganizationId: (organizationId: number) =>
            monolithClient
                .get<GetFactoriesByOrganizationIdResponse>('/factory', { params: { supplierId: organizationId } })
                .then(res => res.data),
    };
}

interface AvailableProductDefinitionItem {
    id: number;
    name: string;
    masterProductId: string;
    grainSize?: {
        min: number;
        max: number;
        unit: string;
    };
    attributeValueIds: string[];
}

interface GetAvailableProductDefinitionsByFactoryResponse {
    items: AvailableProductDefinitionItem[];
}

export function useGetAvailableProductDefinitionsByFactory() {
    const { monolithClient } = useHttpClientContext();
    return {
        getAvailableProductDefinitionsByFactory: (factoryId: number) =>
            monolithClient
                .get<GetAvailableProductDefinitionsByFactoryResponse>(
                    `/v1/factories/${factoryId}/available-product-definitions`
                )
                .then(res => res.data),
    };
}
