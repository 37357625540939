import { z } from 'zod';

const environmentSchema = z.object({
    SFLX_ENVIRONMENT: z.string(),
    OKTA_CLIENT_ID: z.string(),
    FRONTEND_MONOLITH_APP_VERSION: z.string(),
    CONSTRUCTION_PROJECT_BASE_URL: z.string(),
    MONOLITH_BASE_URL: z.string(),
    ORDER_SERVICE_BASE_URL: z.string(),
    FULFILLMENT_SERVICE_BASE_URL: z.string(),
    POSITION_SERVICE_BASE_URL: z.string(),
    CUSTOM_REQUEST_SERVICE_BASE_URL: z.string(),
    USERFLOW_TOKEN: z.string().optional(),
    LAUNCH_DARKLY_CLIENT_ID: z.string(),
    MONOLITH_FE_URL: z.string(),
    SENTRY_ENVIRONMENT: z.string(),
});

export type Environment = z.infer<typeof environmentSchema>;

export const environment = environmentSchema.parse({
    SFLX_ENVIRONMENT: import.meta.env.VITE_SFLX_ENVIRONMENT,
    OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID,
    FRONTEND_MONOLITH_APP_VERSION: import.meta.env.VITE_FRONTEND_MONOLITH_APP_VERSION,
    CONSTRUCTION_PROJECT_BASE_URL: import.meta.env.VITE_CONSTRUCTION_PROJECT_SERVICE_URL,
    MONOLITH_BASE_URL: import.meta.env.VITE_MONOLITH_BASE_URL,
    ORDER_SERVICE_BASE_URL: import.meta.env.VITE_ORDER_SERVICE_URL,
    FULFILLMENT_SERVICE_BASE_URL: import.meta.env.VITE_FULFILLMENT_SERVICE_URL,
    POSITION_SERVICE_BASE_URL: import.meta.env.VITE_POSITION_SERVICE_URL,
    CUSTOM_REQUEST_SERVICE_BASE_URL: import.meta.env.VITE_CUSTOM_REQUEST_SERVICE_URL,
    USERFLOW_TOKEN: import.meta.env.VITE_USERFLOW_TOKEN,
    LAUNCH_DARKLY_CLIENT_ID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    MONOLITH_FE_URL: import.meta.env.VITE_MONOLITH_FE_URL,
    SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
});
