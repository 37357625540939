import { PRODUCT_GROUP_TYPE, PRODUCT_GROUP_TYPES, SUPPORTED_UNIT_LABEL } from '@schuettflix/interfaces';
import { usePlatformCurrencyCode } from '@/clients/platformInfo/usePlatformInfo.ts';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { useTranslation } from 'react-i18next';
import { ProductWithPurifiedWeakQuote } from '../components/ProductGroupBalance';

interface FrancoIndicator {
    amountLabel: string;
    pricePerUnitLabel: string;
}

/**
 * Hook to calculate and compute the labels for the franco indicator of a given list of products
 * @param products - list of products of that group
 * @param productGroupType - the product group type
 * @param templateId - the template id of the group
 * @returns FrancoIndicator | null - the relevant labels for the indicator or null if the group is neither franco nor merchant
 */
export const useFrancoIndicator = (
    products: ProductWithPurifiedWeakQuote[],
    productGroupType: PRODUCT_GROUP_TYPE,
    templateId: string
): FrancoIndicator | null => {
    const { data: currencyCode } = usePlatformCurrencyCode();
    const { t } = useTranslation();
    const { formatNumberWithCurrencyPerUnit } = useFormatters();

    // franco indicator is only relevant for franco and merchant product groups
    if (productGroupType !== PRODUCT_GROUP_TYPES.FRANCO && productGroupType !== PRODUCT_GROUP_TYPES.MERCHANT) {
        return null;
    }

    const relevantProducts = products.filter(product => product.templateId === templateId);
    const leadingProduct = relevantProducts.find(product => product.category === 'MATERIAL');

    if (!leadingProduct || !relevantProducts.length) {
        return null;
    }

    const totalSalesPrice = relevantProducts.reduce((total, product) => {
        return total + product.quote.salesPrice || 0;
    }, 0);

    const amountLabel = `${leadingProduct.quote.amount} ${t(SUPPORTED_UNIT_LABEL[leadingProduct.quote.unit])}`;
    const pricePerUnitLabel = formatNumberWithCurrencyPerUnit(totalSalesPrice, leadingProduct.quote.unit, currencyCode);

    return {
        amountLabel,
        pricePerUnitLabel,
    };
};
