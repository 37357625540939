import {
    PatchPositionProductConfigurationRequest,
    PatchPositionProductConfigurationResponse,
    PatchPositionProductConfigurationResponseSchema,
    PatchPositionProductConfigurationUrl,
} from '@schuettflix/interfaces';
import { useHttpClientContext } from '@/shared/context/HttpClientContext.tsx';

export const usePatchPositionProductConfiguration = () => {
    const { positionClient } = useHttpClientContext();
    return {
        patchPositionProductConfiguration: async ({
            positionProductId,
            positionProductConfiguration,
        }: {
            positionProductId: PatchPositionProductConfigurationUrl['positionProductId'];
            positionProductConfiguration: PatchPositionProductConfigurationRequest;
        }) => {
            return positionClient
                .patch<PatchPositionProductConfigurationResponse>(
                    `/v1/position-product/${positionProductId}/product-configuration`,
                    positionProductConfiguration
                )
                .then(res => PatchPositionProductConfigurationResponseSchema.parse(res.data));
        },
    };
};
