import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { MarketCode, MarketCodeSchema } from '@/shared/types/MarketCode.ts';
import { useIdTokenContext } from '@/shared/context/IdTokenContext.tsx';

export type MarketContextType = {
    marketCode: MarketCode;
    setMarketCode: (newMarketCode: MarketCode) => void;
    setUnsafeMarketCode: (newMarketCode: string) => void;
};

export type MarketContextProviderProps = PropsWithChildren & {
    marketCode: MarketCode;
};

const MarketContext = createContext<MarketContextType | undefined>(undefined);

export function MarketContextProvider({ children, marketCode: defaultMarketCode }: MarketContextProviderProps) {
    const { isMarketAvailable } = useIdTokenContext();

    let marketIsAvailable: boolean = false;
    try {
        marketIsAvailable = isMarketAvailable(defaultMarketCode);
    } catch (e) {
        throw new Error(`IdTokenContext is not initialized`, { cause: e });
    }

    if (!marketIsAvailable) {
        throw new Error(`Authenticated user has no access to the requested market ${defaultMarketCode}`);
    }

    const [marketCode, setMarketCodeState] = useState<MarketCode>(defaultMarketCode);

    const setMarketCode = useCallback<MarketContextType['setMarketCode']>(
        newMarketCode => {
            if (newMarketCode !== marketCode) {
                setMarketCodeState(newMarketCode);
            }
        },
        [marketCode]
    );

    const setUnsafeMarketCode = useCallback<MarketContextType['setUnsafeMarketCode']>(
        newMarketCode => {
            const result = MarketCodeSchema.safeParse(newMarketCode);

            if (!result.success) {
                throw new Error(`Invalid unsafe market code: ${newMarketCode}`);
            }

            setMarketCode(result.data);
        },
        [setMarketCode]
    );

    return (
        <MarketContext.Provider value={{ marketCode, setMarketCode, setUnsafeMarketCode }}>
            {children}
        </MarketContext.Provider>
    );
}

export function useMarketContext() {
    const context = useContext(MarketContext);
    if (!context) {
        throw new Error('MarketContext must be provided!');
    }
    return context;
}
