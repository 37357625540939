import { Select } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { useOrganizationFactories } from '@/clients/factories/useFactories.ts';
import { EditFactoryProductList } from '@/modules/supplierProductManagement/EditFactoryProductList.tsx';
import { useSearchParams } from 'react-router';
import { FullscreenLoadingSuspenseBoundary } from '@/shared/components/FullscreenLoadingSuspenseBoundary.tsx';
import { CreateFactoryProductForm } from '@/modules/supplierProductManagement/CreateFactoryProductForm.tsx';
import React from 'react';
import { SelectOrganization } from '@/shared/components/SelectOrganization.tsx';
import { useOrganization } from '@/clients/organization/useOrganization.ts';

export function SupplierManagementPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const organizationIdSearchParam = searchParams.get('organizationId');
    const selectedOrganizationId = organizationIdSearchParam ? parseInt(organizationIdSearchParam) : null;
    const { data: selectedOrganization } = useOrganization(selectedOrganizationId);
    const { t } = useTranslation();

    const factoryIdSearchParam = searchParams.get('factoryId');
    const selectedFactoryId = factoryIdSearchParam ? parseInt(factoryIdSearchParam) : null;
    const { data: factoriesData } = useOrganizationFactories(selectedOrganizationId);
    const selectedFactory = factoriesData?.items?.find(factory => factory.id === selectedFactoryId);
    return (
        <FullscreenLoadingSuspenseBoundary>
            <section className="lg:px-18 px-2 py-6 xl:px-[122px]">
                <h3 className="font-headline-md-strong pb-4">
                    {t('supply-management.products.organizationSelectionSection.title')}
                </h3>
                <div className="flex gap-4 pb-6">
                    <SelectOrganization
                        className="basis-[350px]"
                        label={t('supply-management.products.organizationSelectionSection.organization.label')}
                        selectedOrganizationId={selectedOrganizationId}
                        value={selectedOrganizationId}
                        onChange={organizationId => {
                            if (organizationId !== null) {
                                setSearchParams(prev => {
                                    prev.set('organizationId', organizationId.toString());
                                    return prev;
                                });
                            }
                        }}
                        filters={{
                            isActive: true,
                            status: ['approved'],
                            organizationType: 'supplier',
                        }}
                    />
                    {factoriesData ? (
                        <Select
                            className="basis-[350px]"
                            value={selectedFactoryId}
                            disabled={factoriesData?.items.length === 0}
                            onChange={factory => {
                                if (factory !== null) {
                                    setSearchParams(prev => {
                                        prev.set('factoryId', factory.toString());
                                        return prev;
                                    });
                                }
                            }}
                            options={factoriesData.items.map(factory => ({
                                label: factory.name,
                                value: factory.id,
                            }))}
                            label="Factory"
                        />
                    ) : null}
                </div>

                {selectedFactory ? (
                    <>
                        <h3 className="font-headline-md-strong pb-4">
                            {t('supply-management.products.createProductSection.title')}
                        </h3>
                        <CreateFactoryProductForm factoryId={selectedFactory.id} />
                    </>
                ) : null}
            </section>
            <main className="bg-light-gray-200 lg:px-18 grow flex-col items-center px-2 pt-8 xl:px-[122px]">
                {selectedFactory && selectedOrganization ? (
                    <div className="max-w-[1700]">
                        <h2 className="font-headline-xl-strong">{selectedFactory.name}</h2>
                        <p className="font-copy-md pb-6">{selectedOrganization.name}</p>
                        <EditFactoryProductList factoryId={selectedFactory.id} />
                    </div>
                ) : null}
                {factoriesData?.items.length === 0 ? <div className="p-4">No Factories</div> : null}
            </main>
        </FullscreenLoadingSuspenseBoundary>
    );
}
