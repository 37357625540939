import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useGetPlatformInfo } from './PlatformInfo';

export function usePlatformInfo() {
    const { getPlatformInfo } = useGetPlatformInfo();

    return useQuery({
        queryKey: ['platformInfo'],
        queryFn: getPlatformInfo,
    });
}

export function usePlatformCurrencyCode() {
    const { getPlatformInfo } = useGetPlatformInfo();

    return useSuspenseQuery({
        queryKey: ['platformInfo'],
        queryFn: getPlatformInfo,
        select: data => data?.platform.market.currencyCode,
    });
}
