import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import React, { useState } from 'react';
import { SellerProduct, useDeleteProduct, usePatchProduct } from '@/clients/sellerProducts/SellerProduct.ts';
import { Button, TextField, ToggleSwitch } from '@schuettflix/react-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { invalidateSellerProductsQuery } from '@/clients/sellerProducts/useSellerProduct.ts';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '@schuettflix/planum-icons-react';

const EditForm = z.object({
    pricePerTon: z.number().positive(),
    stockInTons: z.number().positive(),
    available: z.boolean(),
    isBlockedOnSpotMarket: z.boolean(),
});

export function EditFactoryProductForm({ product, factoryId }: { product: SellerProduct; factoryId: number }) {
    const { t } = useTranslation();

    const [formBodyError, setFormBodyError] = useState<string | null>(null);

    const queryClient = useQueryClient();
    const { patchProduct } = usePatchProduct();
    const { deleteProduct } = useDeleteProduct();
    const editSellerProductMutation = useMutation({
        mutationKey: ['seller-products', 'patch', product.id],
        mutationFn: patchProduct,
        onError: (error: unknown) => {
            const validationError = z
                .object({
                    response: z.object({
                        data: z.array(
                            z.object({
                                property_path: z.string(),
                                message: z.string(),
                            })
                        ),
                    }),
                })
                .safeParse(error);
            if (validationError.success) {
                for (const error of validationError.data.response.data) {
                    switch (error.property_path) {
                        case 'body': {
                            setFormBodyError(error.message);
                            break;
                        }
                    }
                }
            }
        },
    });
    const deleteProductMutation = useMutation({
        mutationKey: ['seller-products', 'delete', product.id],
        mutationFn: deleteProduct,
        onSuccess: async () => {
            await invalidateSellerProductsQuery(queryClient, factoryId);
        },
    });

    const {
        handleSubmit,
        register,
        control,
        formState: { isValid, isDirty },
        reset,
    } = useZodForm({
        schema: EditForm,
        disabled: deleteProductMutation.isPending || editSellerProductMutation.isPending,
        defaultValues: {
            pricePerTon: parseFloat(product.price),
            stockInTons: product.minimumGuaranteedAmount,
            available: product.available,
            isBlockedOnSpotMarket: product.isBlockedOnSpotMarket,
        },
        mode: 'onChange',
    });
    return (
        <>
            <form
                className="flex flex-wrap items-center gap-4"
                onSubmit={handleSubmit(async formData => {
                    setFormBodyError(null);
                    await editSellerProductMutation.mutateAsync({
                        productId: product.id,
                        body: {
                            available: formData.available,
                            price: formData.pricePerTon,
                            minimumGuaranteedAmount: formData.stockInTons,
                            isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                        },
                    });
                    await invalidateSellerProductsQuery(queryClient, factoryId);
                    reset({
                        available: formData.available,
                        pricePerTon: formData.pricePerTon,
                        stockInTons: formData.stockInTons,
                        isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                    });
                })}
            >
                <TextField
                    className="max-w-[300px] basis-[300px]"
                    {...register('pricePerTon', { valueAsNumber: true })}
                    label={t('supply-management.products.form.pricePerTon.label')}
                    step={0.1}
                    type="number"
                />
                <TextField
                    className="max-w-[300px] basis-[300px]"
                    {...register('stockInTons', { valueAsNumber: true })}
                    label={t('supply-management.products.form.stockInTons.label')}
                    step={0.1}
                    type="number"
                />
                <div className="px-2">
                    <Controller
                        control={control}
                        name={'available'}
                        render={({ field }) => (
                            <ToggleSwitch
                                label={t('supply-management.products.form.active.label')}
                                {...field}
                                checked={field.value}
                            />
                        )}
                    />
                </div>
                <div className="px-2">
                    <Controller
                        control={control}
                        name={'isBlockedOnSpotMarket'}
                        disabled={deleteProductMutation.isPending || editSellerProductMutation.isPending}
                        render={({ field }) => (
                            <ToggleSwitch
                                label={t('supply-management.products.form.onlyVisibleForSflx.label')}
                                {...field}
                                checked={field.value}
                            />
                        )}
                    />
                </div>
                <button
                    onClick={async () => {
                        await deleteProductMutation.mutateAsync({ productId: product.id });
                    }}
                    disabled={deleteProductMutation.isPending || editSellerProductMutation.isPending}
                    className="hover:bg-surface-secondary-hovered ml-auto rounded-full p-2"
                >
                    <DeleteIcon size={24} />
                </button>

                <Button
                    label={t('supply-management.products.form.submitButton.label')}
                    type="submit"
                    disabled={
                        !isValid || !isDirty || editSellerProductMutation.isPending || deleteProductMutation.isPending
                    }
                />
            </form>
            {formBodyError ? <p className="text-critical">{formBodyError}</p> : null}
        </>
    );
}
